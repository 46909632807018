import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MainButton, TextButton } from '../buttons';
import { InputForm, ShowForm } from '../forms';
import { TopMessageContainer, SuccessMessage, ErrorMessage } from '../messages';

import { changePassword, resetPasswordStatus, changePasswordSelectors } from '../../features/changePassword/slice';
import { changeMetadata, resetMetadataStatus, changeMetadataSelectors } from '../../features/metadata/slice';
import { appSelectors } from '../../features/app/slice';
import { stopRenewingInvoice, startRenewingInvoice, resetRenewInvoiceStatus, renewInvoiceSelectors } from '../../features/renewInvoice/slice';
import { createInvoiceSelectors } from '../../features/createInvoice/slice';
import { getStatisticsSelectors } from '../../features/statistics/slice';

import { validateStringRequired, validateEmail, validatePassword, validatePasswordMatch, validateRegistrationNumber, validateVatNumber } from '../../helpers/validators';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	contentContainer: {

	},
	buttonContainer: {
		display: 'flex',
		gap: '30px',
		justifyContent: 'center',
		marginTop: '24px',
	},
	secondaryButtonContainer: {
		display: 'flex',
		gap: '30px',
		justifyContent: 'space-evenly',
		marginTop: '15px',
	},
}));

const Profile = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const profileInformation = {
		username: useSelector(appSelectors.username),
		isAdmin: useSelector(appSelectors.isAdmin),
		recentStatistics: useSelector(getStatisticsSelectors.recentStatistics),
		invoiceEnds: useSelector(createInvoiceSelectors.invoiceEnds),
		invoiceRenewed: useSelector(createInvoiceSelectors.invoiceRenewed),
		invoicePaid: useSelector(createInvoiceSelectors.invoicePaid),
		email: useSelector(appSelectors.email),
		email_verified_at: useSelector(appSelectors.email_verified_at),
		metadata: useSelector(appSelectors.metadata),
		created_at: useSelector(appSelectors.created_at),
		trialPeriod: useSelector(appSelectors.trialPeriod),
	};

	const renewInvoiceStatus = useSelector(renewInvoiceSelectors.renewStatus);
	const renewInvoiceError = useSelector(renewInvoiceSelectors.error);

	const changePasswordStatus = useSelector(changePasswordSelectors.changeStatus);
	const changePasswordError = useSelector(changePasswordSelectors.error);

	const changeMetadataStatus = useSelector(changeMetadataSelectors.changeStatus);
	const changeMetadataError = useSelector(changeMetadataSelectors.error);

	const [mode, setMode] = useState('show');
	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const showContent = [{
		label: 'Vārds',
		value: profileInformation.username,
	}, {
		label: 'E-pasta adrese',
		value: profileInformation.email,
	}, {
		label: 'E-pasts apstiprināts',
		value: (profileInformation.email_verified_at ? 'Jā' : 'Nē'),
	}, {
		label: 'Konts izveidots',
		value: new Date(profileInformation.created_at).toLocaleDateString('lv-LV'),
	}, {
		label: 'Statuss',
		value: (profileInformation.invoiceEnds ? (profileInformation.recentStatistics <= 200 ? 'Maksas' : 'Maksas+') : 'Bezmaksas'),
	}, {
		label: 'Rēķins',
		value: (profileInformation.invoiceEnds ? (profileInformation.invoicePaid ? 'Apmaksāts' : 'Neapmaksāts') : 'Bezmaksas'),
	}, {
		label: 'Rēķina atjaunošana',
		value: (profileInformation.invoiceEnds ? (profileInformation.invoiceRenewed ? 'Jā' : 'Nē') : 'Bezmaksas'),
	}, {
		label: 'Uzņēmums',
		value: profileInformation.metadata.company,
	}, {
		label: 'Maksātāja adrese',
		value: profileInformation.metadata.billing_address,
	}, {
		label: 'Maksātāja e-pasts',
		value: profileInformation.metadata.billing_email,
	}, {
		label: 'Reģistrācijas Nr.',
		value: profileInformation.metadata.registration_number,
	}, {
		label: 'PVN maksātāja Nr.',
		value: profileInformation.metadata.vat_number,
	}];

	const handleEdit = () => {
		const name = editInfo.username;
		const metadata = {
			company: (editInfo.company ? editInfo.company : ''),
			billing_address: (editInfo.billing_address ? editInfo.billing_address : ''),
			billing_email: (editInfo.billing_email ? editInfo.billing_email : ''),
			registration_number: (editInfo.registration_number ? editInfo.registration_number : ''),
			vat_number: (editInfo.vat_number ? editInfo.vat_number : '')
		};

		if (validateForm()) {
			dispatch(changeMetadata({ name, metadata }));
		}
	}

	const [editInfo, setEditInfo] = useState({
		username: profileInformation.username,
		company: (profileInformation.metadata.company ? profileInformation.metadata.company : ''),
		billing_address: (profileInformation.metadata.billing_address ? profileInformation.metadata.billing_address : ''),
		billing_email: (profileInformation.metadata.billing_email ? profileInformation.metadata.billing_email : ''),
		registration_number: (profileInformation.metadata.registration_number ? profileInformation.metadata.registration_number : ''),
		vat_number: (profileInformation.metadata.vat_number ? profileInformation.metadata.vat_number : ''),
	});

	const [editInputErrors, setEditInputErrors] = useState({
		username: '',
		company: '',
		billing_address: '',
		billing_email: '',
		registration_number: '',
		vat_number: '',
	});

	const editContent = [{
		label: 'Vārds',
		value: editInfo.username,
		valueType: 'stringRequired',
		valueName: 'username',
		errors: editInputErrors.username,
		validationMessage: 'vārdu',
	}, {
		label: 'Uzņēmums',
		value: editInfo.company,
		valueName: 'company',
		errors: editInputErrors.company,
	}, {
		label: 'Maksātāja adrese',
		value: editInfo.billing_address,
		valueType: 'stringRequired',
		valueName: 'billing_address',
		errors: editInputErrors.billing_address,
		validationMessage: 'maksātāja adresi',
	}, {
		label: 'Maksātāja e-pasts',
		value: editInfo.billing_email,
		valueType: 'email',
		valueName: 'billing_email',
		errors: editInputErrors.billing_email,
		validationMessage: 'maksātāja e-pasta adresi',
	}, {
		label: 'Reģistrācijas Nr.',
		value: editInfo.registration_number,
		valueType: 'registrationNumber',
		valueName: 'registration_number',
		errors: editInputErrors.registration_number,
		validationMessage: 'reģistrācijas numuru',
	}, {
		label: 'PVN maksātāja Nr.',
		value: editInfo.vat_number,
		valueType: 'vatNumber',
		valueName: 'vat_number',
		errors: editInputErrors.vat_number,
		validationMessage: 'PVN maksātāja numuru',
	}];

	const handleChangePassword = () => {
		const old_password = changePasswordInfo.old_password;
		const password = changePasswordInfo.password;

		if (validateForm()) {
			dispatch(changePassword({ old_password, password }));
		}
	}

	const [changePasswordInfo, setChangePasswordInfo] = useState({
		old_password: '',
		password: '',
		passwordRepeat: '',
		toggleOldVisiblity: false,
		toggleVisiblity: false,
		toggleRepeatVisiblity: false
	});

	const [changePasswordInputErrors, setChangePasswordInputErrors] = useState({
		old_password: '',
		password: '',
		passwordRepeat: '',
	});

	const changePasswordContent = [{
		label: 'Esošā parole',
		value: changePasswordInfo.old_password,
		valueType: 'password',
		valueName: 'old_password',
		errors: changePasswordInputErrors.old_password,
		validationMessage: 'esošo paroli',
		visible: changePasswordInfo.toggleOldVisiblity,
		visibleName: 'toggleOldVisiblity',
	}, {
		label: 'Jaunā parole',
		value: changePasswordInfo.password,
		valueType: 'password',
		valueName: 'password',
		errors: changePasswordInputErrors.password,
		validationMessage: 'paroli',
		visible: changePasswordInfo.toggleVisiblity,
		visibleName: 'toggleVisiblity',
	}, {
		label: 'Parole atkārtoti',
		value: changePasswordInfo.passwordRepeat,
		valueType: 'passwordRepeat',
		valueName: 'passwordRepeat',
		errors: changePasswordInputErrors.passwordRepeat,
		validationMessage: 'paroli atkārtoti',
		visible: changePasswordInfo.toggleRepeatVisiblity,
		visibleName: 'toggleRepeatVisiblity',
	}];

	const resetAlertMessages = () => {
		setSuccessMessage('');
		setErrorMessage('');
	}

	const handleSwitchMode = (mode) => {
		resetAlertMessages();
		setMode(mode);
	}

	const handleReturn = () => {
		switch (mode) {
			case 'edit':
				setEditInfo({
					username: profileInformation.username,
					company: (profileInformation.metadata.company ? profileInformation.metadata.company : ''),
					billing_address: (profileInformation.metadata.billing_address ? profileInformation.metadata.billing_address : ''),
					billing_email: (profileInformation.metadata.billing_email ? profileInformation.metadata.billing_email : ''),
					registration_number: (profileInformation.metadata.registration_number ? profileInformation.metadata.registration_number : ''),
					vat_number: (profileInformation.metadata.vat_number ? profileInformation.metadata.vat_number : ''),
				});

				setEditInputErrors({
					username: '',
					company: '',
					billing_address: '',
					billing_email: '',
					registration_number: '',
					vat_number: '',
				});

				break;

			case 'password':
				setChangePasswordInfo({
					old_password: '',
					password: '',
					passwordRepeat: '',
					toggleOldVisiblity: false,
					toggleVisiblity: false,
					toggleRepeatVisiblity: false
				});

				setChangePasswordInputErrors({
					old_password: '',
					password: '',
					passwordRepeat: '',
				});

				break;
		}

		handleSwitchMode('show');
	}

	const validateForm = () => {
		let valid = true;
		let error = '';
		let temp = null;

		switch (mode) {
			case 'edit':
				temp = editInputErrors;

				for (const item of editContent) {
					if (!('valueType' in item)) continue;

					if (item.valueType === 'stringRequired') {
						error = validateStringRequired(editInfo[item.valueName], item.validationMessage);

					} else if (item.valueType === 'email') {
						error = validateEmail(editInfo[item.valueName], item.validationMessage);

					} else if (item.valueType === 'registrationNumber') {
						error = validateRegistrationNumber(editInfo[item.valueName], item.validationMessage);

					} else if (item.valueType === 'vatNumber') {
						error = validateVatNumber(editInfo[item.valueName], item.validationMessage);
					}

					valid = (valid && error === '');
					temp[item.valueName] = error;
				}

				setEditInputErrors({
					username: temp.username,
					company: temp.company,
					billing_address: temp.billing_address,
					billing_email: temp.billing_email,
					registration_number: temp.registration_number,
					vat_number: temp.vat_number,
				});

				return valid;

			case 'password':
				temp = changePasswordInputErrors;

				for (const item of changePasswordContent) {
					if (!('valueType' in item)) continue;

					if (item.valueType === 'password') {
						error = validatePassword(changePasswordInfo[item.valueName], item.validationMessage);

					} else if (item.valueType === 'passwordRepeat') {
						error = validatePassword(changePasswordInfo[item.valueName], item.validationMessage);
						error = (error === '' ? validatePasswordMatch(changePasswordInfo.password, changePasswordInfo[item.valueName]) : error);
					}

					valid = (valid && error === '');
					temp[item.valueName] = error;
				}

				setChangePasswordInputErrors({
					old_password: temp.old_password,
					password: temp.password,
					passwordRepeat: temp.passwordRepeat,
				});

				return valid;

			default:
				return true;
		}
	}

	let content;

	switch (mode) {
		case 'show':
			if (renewInvoiceStatus === 'success') {
				dispatch(resetRenewInvoiceStatus());
				setSuccessMessage('Abonēšanas statuss tika veiksmīgi mainīts!');

			} else if (errorMessage === '' && renewInvoiceError !== '') {
				setErrorMessage(renewInvoiceError);
				dispatch(resetRenewInvoiceStatus());
			}

			content =
				<div className={classes.contentContainer}>
					<ShowForm formContent={showContent} />

					<div className={classes.buttonContainer}>
						<MainButton disabled={renewInvoiceStatus === 'started'} onClick={() => handleSwitchMode('edit')}>Mainīt informāciju</MainButton>
						<MainButton disabled={renewInvoiceStatus === 'started'} onClick={() => handleSwitchMode('password')}>Mainīt paroli</MainButton>
					</div>

					{!profileInformation.trialPeriod &&
						<div className={classes.secondaryButtonContainer}>
							{profileInformation.invoiceEnds && profileInformation.invoiceRenewed
								? <TextButton disabled={renewInvoiceStatus === 'started'} onClick={() => dispatch(stopRenewingInvoice())}>Atteikties no abonēšanas</TextButton>
								: <TextButton disabled={renewInvoiceStatus === 'started'} onClick={() => dispatch(startRenewingInvoice())}>Atsākt abonēšanu</TextButton>
							}
						</div>
					}
				</div>
			;

			break;

		case 'edit':
			if (changeMetadataStatus === 'success') {
				dispatch(resetMetadataStatus());
				handleReturn();
				setSuccessMessage('Profila informācija tika veiksmīgi mainīta!');

			} else if (errorMessage === '' && changeMetadataError !== '') {
				setErrorMessage(changeMetadataError);
				dispatch(resetMetadataStatus());
			}

			content =
				<div className={classes.contentContainer}>
					<InputForm
						formContent={editContent}
						setFormInfo={setEditInfo}
						formInfo={editInfo}
					/>

					<div className={classes.buttonContainer}>
						<MainButton disabled={changeMetadataStatus === 'started'} onClick={handleReturn}>Atpakaļ</MainButton>
						<MainButton disabled={changeMetadataStatus === 'started'} onClick={handleEdit}>Saglabāt</MainButton>
					</div>
				</div>
			;

			break;

		case 'password':
			if (changePasswordStatus === 'success') {
				dispatch(resetPasswordStatus());
				handleReturn();
				setSuccessMessage('Parole tika veiksmīgi mainīta!');

			} else if (errorMessage === '' && changePasswordError !== '') {
				setErrorMessage(changePasswordError);
				dispatch(resetPasswordStatus());
			}

			content =
				<div className={classes.contentContainer}>
					<InputForm
						formContent={changePasswordContent}
						setFormInfo={setChangePasswordInfo}
						formInfo={changePasswordInfo}
					/>

					<div className={classes.buttonContainer}>
						<MainButton disabled={changePasswordStatus === 'started'} onClick={handleReturn}>Atpakaļ</MainButton>
						<MainButton disabled={changePasswordStatus === 'started'} onClick={handleChangePassword}>Saglabāt</MainButton>
					</div>
				</div>
			;

			break;
	}

	return (
		<div className={classes.container}>
			<TopMessageContainer>
				{successMessage !== '' && <SuccessMessage message={successMessage} onClose={() => { setSuccessMessage('') }} />}
				{errorMessage !== '' && <ErrorMessage message={errorMessage} onClose={() => { setErrorMessage('') }} />}
			</TopMessageContainer>

			{content}
		</div>
	);
}

export default Profile;
