import React, { useState } from 'react';

import Profile from '../profile';
import Statistics from '../statistics';
import MenuList from '../menuList';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		alignItems: 'flex-start',
		display: 'flex',
		gap: '40px',
		justifyContent: 'space-between',

		'@media (max-width: 850px)': {
			flexDirection: 'column',
		},
	},
	rightContent: {
		width: '600px',

		'@media (max-width: 850px)': {
			width: '100%',
		},
	},
}));

const AccountInfo = () => {
	const classes = useStyles();

	const [activeSection, setActiveSection] = useState('profile');

	let section;

	switch (activeSection) {
		case 'profile':
			section = <Profile />;
			break;

		case 'statistics':
			section = <Statistics />;
			break;
	}

	const menus = [{
		properties: {
			id: 'profile',
			name: 'Profila informācija',
		}
	}, {
		properties: {
			id: 'statistics',
			name: 'Statistika',
		}
	}];

	return (
		<div className={classes.container}>
			<MenuList listContents={menus} onClick={setActiveSection} selectedIndex={activeSection} />

			<div className={classes.rightContent}>
				{section}
			</div>
		</div>
	);
}

export default AccountInfo;
