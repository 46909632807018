import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	mainButton: {
		backgroundColor: '#000000',
		borderRadius: '2px',
		boxShadow: 'none',
		color: '#FFFFFF',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '16px',
		fontWeight: '500',
		padding: '4px 16px',

		'&:hover': {
			backgroundColor: '#4DCF10',
		}
	},
	textButton: {
		backgroundColor: 'unset',
		boxShadow: 'none',
		color: '#847D6F',
		cursor: 'pointer',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '500',
		userSelect: 'none',

		'&:hover': {
			backgroundColor: 'unset',
			color: '#000000',
			textDecoration: 'underline',
			textDecorationColor: '#4DCF10',
			textUnderlineOffset: '2px',
			textDecorationThickness: '2px',
		}
	},
}));

export const MainButton = props => {
	const classes = useStyles();

	return (
		<Button variant="contained" className={classes.mainButton} {...props} />
	);
};

export const TextButton = props => {
	const classes = useStyles();

	return (
		<Button className={classes.textButton} {...props} />
	);
};

export const TextButtonNoPadding = props => {
	const classes = useStyles();

	return (
		<span className={classes.textButton} {...props} />
	);
};
