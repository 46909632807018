import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import LoginAbout from '../loginAbout';
import OverlayText from '../overlayText';
import { MainButton, TextButton } from '../buttons';
import { InputForm } from '../forms';
import { SuccessMessage, ErrorMessage } from '../messages';

import { checkAuth, login, resetAuthStatus, selectors } from '../../features/auth/slice';
import { register, resetRegisterStatus, registerSelectors } from '../../features/register/slice';
import { forgotPassword, resetForgotPasswordStatus, passwordSelectors } from '../../features/forgotPassword/slice';
import { clearRequestData } from '../../features/data/slice';
import { resetPasswordStatus, resetSelectors } from '../../features/resetPassword/slice';

import { validateStringRequired, validateEmail, validatePassword, validatePasswordMatch } from '../../helpers/validators';

import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	homeContainer: {
		alignItems: 'flex-start',
		display: 'flex',
		justifyContent: 'space-between',

		'@media (max-width: 850px)': {
			flexDirection: 'column',
			gap: '15px',
		},
	},
	loginBox: {
		backgroundColor: 'unset',
		margin: 'auto',
		borderRadius: '0',
		width: '500px',

		'@media (max-width: 850px)': {
			width: '100%',
		},
	},
	messageContainer: {
		display: 'flex',
		gap: '5px',
		flexDirection: 'column',
		marginBottom: '10px',
	},
	message: {
		margin: '14px 7px',
		padding: '10px',
	},
	title: {
		color: '#847D6F',
		fontWeight: '500',
		marginBottom: '30px',
		textAlign: 'center',
	},
	buttonContainer: {
		display: 'flex',
		gap: '30px',
		justifyContent: 'center',
		marginTop: '24px',
	},
	secondaryButtonContainer: {
		display: 'flex',
		gap: '30px',
		justifyContent: 'space-evenly',
		marginTop: '15px',
	},
	backgroundOverlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
		height: '100%',
		left: '0',
		position: 'absolute',
		top: '0',
		width: '100%',
		zIndex: '2',
	},
	overlayContainer: {
		backgroundColor: 'white',
		boxSizing: 'border-box',
		boxShadow: '0 0 5px 1px rgb(0 0 0 / 50%)',
		display: 'flex',
		flexDirection: 'column',
		height: '400px',
		left: '50%',
		padding: '30px',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		width: '800px',
		zIndex: '1',

		'@media (max-width: 850px)': {
			width: 'calc(100% - 40px)',
		}
	},
	overlayTextBox: {
		backgroundColor: 'white',
		height: '100%',
		overflowY: 'auto',
		paddingRight: '10px',

		'& h2': {
			marginTop: '25px',
			marginBottom: '25px',
		},

		'& h3': {
			marginTop: '20px',
			marginBottom: '20px',
		},

		'& p': {
			marginBottom: '10px',
		},

		'& li': {
			listStyleType: 'disc',
			marginLeft: '20px'
		}
	}
}));

const Login = ({ sectionChange }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const [mode, setMode] = useState('login');
	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [showOverlay, setShowOverlay] = useState(false);

	const isAppReady = useSelector(selectors.isAppReady);

	const authSuccess = useSelector(selectors.authSuccess);
	const authStatus = useSelector(selectors.authStatus);
	const authError = useSelector(selectors.error);

	const registerStatus = useSelector(registerSelectors.registerStatus);
	const registerError = useSelector(registerSelectors.error);

	const passwordStatus = useSelector(passwordSelectors.sendStatus);
	const passwordError = useSelector(passwordSelectors.error);

	const resetStatus = useSelector(resetSelectors.resetStatus);

	const handleLogin = () => {
		const email = loginInfo.email;
		const password = loginInfo.password;

		if (validateForm()) {
			dispatch(clearRequestData());
			dispatch(login({ email, password }));
		}
	}

	const [loginInfo, setLoginInfo] = useState({
		email: '',
		password: '',
		toggleVisiblity: false
	});

	const [loginInputErrors, setLoginInputErrors] = useState({
		email: '',
		password: ''
	});

	const loginContent = [{
		label: 'E-pasts',
		value: loginInfo.email,
		valueType: 'email',
		valueName: 'email',
		errors: loginInputErrors.email,
		validationMessage: 'e-pasta adresi',
	}, {
		label: 'Parole',
		value: loginInfo.password,
		valueType: 'password',
		valueName: 'password',
		errors: loginInputErrors.password,
		validationMessage: 'paroli',
		visible: loginInfo.toggleVisiblity,
		visibleName: 'toggleVisiblity',
	}];

	const handleCheckboxClick = () => {
		!registerInfo.tosAccepted ? setRegisterInfo(registerInfo => ({ ...registerInfo, ...{ tosAccepted: true } })) : setRegisterInfo(registerInfo => ({ ...registerInfo, ...{ tosAccepted: false } }));
	};

	const handleRegister = () => {
		const name = registerInfo.name;
		const email = registerInfo.email;
		const password = registerInfo.password;

		if (validateForm()) {
			dispatch(register({ name, email, password }));
		}
	}

	const handleAgree = () => {
		setRegisterInfo(registerInfo => ({ ...registerInfo, ...{ tosAccepted: true } }))
		setShowOverlay(false);
	};

	const [registerInfo, setRegisterInfo] = useState({
		name: '',
		email: '',
		password: '',
		passwordRepeat: '',
		toggleVisiblity: false,
		toggleRepeatVisiblity: false,
		tosAccepted: false
	});

	const [registerInputErrors, setRegisterInputErrors] = useState({
		name: '',
		email: '',
		password: '',
		passwordRepeat: '',
		tosAccepted: ''
	});

	const registerContent = [{
		label: 'Vārds',
		value: registerInfo.name,
		valueType: 'stringRequired',
		valueName: 'name',
		errors: registerInputErrors.name,
		validationMessage: 'vārdu',
	}, {
		label: 'E-pasts',
		value: registerInfo.email,
		valueType: 'email',
		valueName: 'email',
		errors: registerInputErrors.email,
		validationMessage: 'e-pasta adresi',
	}, {
		label: 'Parole',
		value: registerInfo.password,
		valueType: 'password',
		valueName: 'password',
		errors: registerInputErrors.password,
		validationMessage: 'paroli',
		visible: registerInfo.toggleVisiblity,
		visibleName: 'toggleVisiblity',
	}, {
		label: 'Parole atkārtoti',
		value: registerInfo.passwordRepeat,
		valueType: 'passwordRepeat',
		valueName: 'passwordRepeat',
		errors: registerInputErrors.passwordRepeat,
		validationMessage: 'paroli atkārtoti',
		visible: registerInfo.toggleRepeatVisiblity,
		visibleName: 'toggleRepeatVisiblity',
	}, {
		label: '',
		value: registerInfo.tosAccepted,
		valueType: 'checkbox',
		valueName: 'tosAccepted',
		errors: registerInputErrors.tosAccepted,
		validationMessage: 'noteikumus',
		primaryFunction: handleCheckboxClick,
		secondaryFunction: setShowOverlay,
	}];

	const handleResetPassword = () => {
		const email = passwordResetInfo.email;

		if (validateForm()) {
			dispatch(forgotPassword({ email }));
		}
	}

	const [passwordResetInfo, setPasswordResetInfo] = useState({
		email: '',
	});

	const [passwordResetErrors, setPasswordResetErrors] = useState({
		email: '',
	});

	const passwordResetContent = [{
		label: 'E-pasts',
		value: passwordResetInfo.email,
		valueType: 'email',
		valueName: 'email',
		errors: passwordResetErrors.email,
		validationMessage: 'e-pasta adresi',
	}];

	useEffect(() => {
		// dispatch(checkAuth());
	}, []);

	const resetAlertMessages = () => {
		setSuccessMessage('');
		setErrorMessage('');
	}

	const handleSwitchMode = (mode) => {
		resetAlertMessages();
		setMode(mode);
	}

	const validateForm = () => {
		let valid = true;
		let error = '';
		let temp = null;

		switch (mode) {
			case 'login':
				temp = loginInputErrors;

				for (const item of loginContent) {
					if (!('valueType' in item)) continue;

					if (item.valueType === 'email') {
						error = validateEmail(loginInfo[item.valueName], item.validationMessage);

					} else if (item.valueType === 'password') {
						error = validatePassword(loginInfo[item.valueName], item.validationMessage);
					}

					valid = (valid && error === '');
					temp[item.valueName] = error;
				}

				setLoginInputErrors({
					email: temp.email,
					password: temp.password
				});

				return valid;

			case 'register':
				temp = registerInputErrors;

				for (const item of registerContent) {
					if (!('valueType' in item)) continue;

					if (item.valueType === 'stringRequired') {
						error = validateStringRequired(registerInfo[item.valueName], item.validationMessage);

					} else if (item.valueType === 'email') {
						error = validateEmail(registerInfo[item.valueName], item.validationMessage);

					} else if (item.valueType === 'password') {
						error = validatePassword(registerInfo[item.valueName], item.validationMessage);

					} else if (item.valueType === 'passwordRepeat') {
						error = validatePassword(registerInfo[item.valueName], item.validationMessage);
						error = (error === '' ? validatePasswordMatch(registerInfo.password, registerInfo[item.valueName]) : error);
					}

					valid = (valid && error === '');
					temp[item.valueName] = error;
				}

				if (registerInfo.tosAccepted === false) {
					temp.tosAccepted = 'Apstipriniet noteikumus!';
					valid = false;

				} else if (registerInfo.tosAccepted) {
					temp.tosAccepted = '';
				}

				setRegisterInputErrors({
					name: temp.name,
					email: temp.email,
					password: temp.password,
					passwordRepeat: temp.passwordRepeat,
					tosAccepted: temp.tosAccepted
				});

				return valid;

			case 'password':
				temp = passwordResetErrors;

				for (const item of passwordResetContent) {
					if (item.valueType === 'email') {
						error = validateEmail(passwordResetInfo[item.valueName], item.validationMessage);
					}

					valid = (valid && error === '');
					temp[item.valueName] = error;
				}

				setPasswordResetErrors({
					email: temp.email,
				});

				return valid;

			default:
				return true;
		}
	};

	const overlay = !showOverlay ? ''
		: (
			<div className={classes.backgroundOverlay}>
				<div className={classes.overlayContainer}>
					<OverlayText />

					<div className={classes.buttonContainer}>
						<MainButton onClick={() => setShowOverlay(false)}>Aizvērt</MainButton>
						<MainButton onClick={() => handleAgree()}>Piekrist</MainButton>
					</div>
				</div>
			</div>
		);

	let content;

	switch (mode) {
		case 'login':
			if (errorMessage === '' && authError !== '') {
				setErrorMessage(authError);
				dispatch(resetAuthStatus());

			} else if (resetStatus === 'success') {
				dispatch(resetPasswordStatus());

			} else if (searchParams.get('token')) {
				setSuccessMessage('Parole tika veiksmīgi atjaunota!');
				searchParams.delete('token');
				setSearchParams(searchParams);
				window.history.replaceState('', '', '/');
			}

			content =
				<Paper className={classes.loginBox} elevation={0}>
					<div>
						<h4 className={classes.title}>Ienākt</h4>

						{(successMessage !== '' || errorMessage !== '') && <div className={classes.messageContainer}>
							{successMessage !== '' && <SuccessMessage message={successMessage} onClose={() => { setSuccessMessage('') }} />}
							{errorMessage !== '' && <ErrorMessage message={errorMessage} onClose={() => { setErrorMessage('') }} />}
						</div>}
					</div>

					<InputForm
						formContent={loginContent}
						setFormInfo={setLoginInfo}
						formInfo={loginInfo}
					/>

					<div className={classes.buttonContainer}>
						<MainButton disabled={authStatus === 'started'} onClick={handleLogin}>Autentificēties</MainButton>
					</div>

					<div className={classes.secondaryButtonContainer}>
						<TextButton disabled={authStatus === 'started'} onClick={() => handleSwitchMode('register')}>Reģistrēties</TextButton>
						<TextButton disabled={authStatus === 'started'} onClick={() => handleSwitchMode('password')}>Aizmirsu paroli</TextButton>
					</div>
				</Paper>
			;

			break;

		case 'register':
			if (errorMessage === '' && registerError !== '') {
				setErrorMessage(registerError);
				dispatch(resetRegisterStatus());
			}

			content =
				<Paper className={classes.loginBox} elevation={0}>
					<div>
						<h4 className={classes.title}>Reģistrēties</h4>

						{errorMessage !== '' && <div className={classes.messageContainer}>
							{errorMessage !== '' && <ErrorMessage message={errorMessage} onClose={() => { setErrorMessage('') }} />}
						</div>}
					</div>

					<InputForm
						formContent={registerContent}
						setFormInfo={setRegisterInfo}
						formInfo={registerInfo}
					/>

					<div className={classes.buttonContainer}>
						<MainButton disabled={registerStatus === 'started'} onClick={() => handleSwitchMode('login')}>Atpakaļ</MainButton>
						<MainButton disabled={registerStatus === 'started'} onClick={handleRegister}>Izveidot kontu</MainButton>
					</div>
				</Paper>
			;

			break;

		case 'password':
			if (passwordStatus === 'success') {
				dispatch(resetForgotPasswordStatus());
				handleSwitchMode('login');
				setSuccessMessage('Paroles atjaunošanas e-pasts tika veiksmīgi nosūtīts!');

			} else if (errorMessage === '' && passwordError !== '') {
				setErrorMessage(passwordError);
				dispatch(resetForgotPasswordStatus());
			}

			content =
				<Paper className={classes.loginBox} elevation={0}>
					<div>
						<h4 className={classes.title}>Atjaunot paroli</h4>

						{errorMessage !== '' && <div className={classes.messageContainer}>
							{errorMessage !== '' && <ErrorMessage message={errorMessage} onClose={() => { setErrorMessage('') }} />}
						</div>}
					</div>

					<InputForm
						formContent={passwordResetContent}
						setFormInfo={setPasswordResetInfo}
						formInfo={passwordResetInfo}
					/>

					<div className={classes.buttonContainer}>
						<MainButton disabled={passwordStatus === 'started'} onClick={() => handleSwitchMode('login')}>Atpakaļ</MainButton>
						<MainButton disabled={passwordStatus === 'started'} onClick={handleResetPassword}>Atjaunot</MainButton>
					</div>
				</Paper>
			;

			break;
	}

	return (
		<div className={classes.homeContainer}>
			<LoginAbout sectionChange={sectionChange} />

			{mode === 'register' ? overlay : ''}
			{content}
		</div>
	);
}

export default Login;
