import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import app from './app/slice';
import auth from './auth/slice';
import changePassword from './changePassword/slice';
import createInvoice from './createInvoice/slice';
import data from './data/slice';
import forgotPassword from './forgotPassword/slice';
import metadata from './metadata/slice';
import renewInvoice from './renewInvoice/slice';
import resetPassword from './resetPassword/slice';
import verifyEmail from './verifyEmail/slice';
import statistics from './statistics/slice';
import register from './register/slice';
import notifier from './notifier/slice';

export const reducers = {
	app,
	auth,
	changePassword,
	createInvoice,
	data,
	forgotPassword,
	metadata,
	renewInvoice,
	resetPassword,
	verifyEmail,
	statistics,
	register,
	notifier,
};

export default configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(logger),
	devTools: process.env.NODE_ENV !== 'production',
});
