import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	textBox: {
		width: '500px',

		'& p': {
			marginBottom: '25px',
		},

		'@media (max-width: 850px)': {
			width: '100%',
		},
	}
}));

const About = () => {
	const classes = useStyles();

	return (
		<div className={classes.textBox}>
			<p>"Burtnieks" ir vietvārdu atveidošanas platforma, kas nodrošina vairāk nekā 100 000 vietvārdu latviešu valodā. Risinājumā izmantotā vietvārdu datubāze balstās uz "Jāņa sētas" 2008.gadā izdoto Lielo pasaules atlantu, kas bija un joprojām ir lielākais ģeogrāfisko nosaukumu krājums, kas atveidots latviešu valodā. Šis izdevums daudziem profesionāļiem kalpojusi kā rokasgrāmata ģeogrāfisko nosaukums attēlošanai latviešu valodā vai pretēji - latviski izlasīta vārda attēlošanai oriģinālvalodā.</p>
			<p>"Burtnieks" ļauj apskatīt "Jāņa sētas" datubāzē pieejamos ārvalstu vietvārdu atveidojumus latviešu valodā, kā arī palīdz atrast latviešu valodā atveidotus vietvārdus to oriģinālvalodā (latīņu transliterācijā). Daļai vietvārdu pieejami atveidojumi arī igauņu un lietuviešu valodās. Lietotājam pieejama ērta saskarne, kas ļauj atrast vietvārdu ar meklētāja loga palīdzību, ļaujot noteikt arī atrastā vietvārda tipu (apdzīvota vieta, administratīva teritorija, upe u.tml.).</p>
			<p>Ikvienam ir iespēja izmēģināt "Burtnieku" bez maksas, bet profesionālos lietotājus aicinām kļūt par "Burtnieka" abonentiem.</p>
		</div>
	);
}

export default About;
