import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { MainButton } from '../buttons';
import { InputForm } from '../forms';
import { ErrorMessage } from '../messages';

import { selectors } from '../../features/auth/slice';
import { resetPassword, resetPasswordStatus, resetSelectors } from '../../features/resetPassword/slice';

import { validateEmail, validatePassword, validatePasswordMatch } from '../../helpers/validators';

import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	loginBoxContainer: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		left: '0',
		position: 'absolute',
		top: '0',
		width: '100%',

		'@media (max-width: 850px)': {
			left: 'auto',
			width: 'calc(100% - 40px)',
		},
	},
	loginBox: {
		backgroundColor: 'unset',
		width: '500px',

		'@media (max-width: 850px)': {
			width: '100%',
		},
	},
	title: {
		color: '#847D6F',
		fontWeight: '500',
		marginBottom: '30px',
		textAlign: 'center',
	},
	messageContainer: {
		display: 'flex',
		gap: '5px',
		flexDirection: 'column',
		marginBottom: '10px',
	},
	buttonContainer: {
		display: 'flex',
		gap: '30px',
		justifyContent: 'center',
		marginTop: '24px',
	},
}));

const ResetPassword = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const isAppReady = useSelector(selectors.isAppReady);

	const resetStatus = useSelector(resetSelectors.resetStatus);
	const errorMessage = useSelector(resetSelectors.error);

	const handleResetPassword = () => {
		const email = resetPasswordInfo.email;
		const password = resetPasswordInfo.password;
		const password_confirmation = resetPasswordInfo.passwordRepeat;
		const token = searchParams.get('token');

		if (validateForm()) {
			dispatch(resetPassword({ email, password, password_confirmation, token }));
		}
	}

	const [resetPasswordInfo, setResetPasswordInfo] = useState({
		email: '',
		password: '',
		passwordRepeat: '',
		toggleVisiblity: false,
		toggleRepeatVisiblity: false
	});

	const [resetPasswordInputErrors, setResetPasswordInputErrors] = useState({
		email: '',
		password: '',
		passwordRepeat: ''
	});

	const resetPasswordContent = [{
		label: 'E-pasts',
		value: resetPasswordInfo.email,
		valueType: 'email',
		valueName: 'email',
		errors: resetPasswordInputErrors.email,
		validationMessage: 'e-pasta adresi',
	}, {
		label: 'Jaunā parole',
		value: resetPasswordInfo.password,
		valueType: 'password',
		valueName: 'password',
		errors: resetPasswordInputErrors.password,
		validationMessage: 'paroli',
		visible: resetPasswordInfo.toggleVisiblity,
		visibleName: 'toggleVisiblity',
	}, {
		label: 'Parole atkārtoti',
		value: resetPasswordInfo.passwordRepeat,
		valueType: 'passwordRepeat',
		valueName: 'passwordRepeat',
		errors: resetPasswordInputErrors.passwordRepeat,
		validationMessage: 'paroli atkārtoti',
		visible: resetPasswordInfo.toggleRepeatVisiblity,
		visibleName: 'toggleRepeatVisiblity',
	}];

	const [searchParams, setSearchParams] = useSearchParams();

	const validateForm = () => {
		let valid = true;
		let error = '';
		let temp = null;

		temp = resetPasswordInputErrors;

		for (const item of resetPasswordContent) {
			if (!('valueType' in item)) continue;

			if (item.valueType === 'email') {
				error = validateEmail(resetPasswordInfo[item.valueName], item.validationMessage);

			} else if (item.valueType === 'password') {
				error = validatePassword(resetPasswordInfo[item.valueName], item.validationMessage);

			} else if (item.valueType === 'passwordRepeat') {
				error = validatePassword(resetPasswordInfo[item.valueName], item.validationMessage);
				error = (error === '' ? validatePasswordMatch(resetPasswordInfo.password, resetPasswordInfo[item.valueName]) : error);
			}

			valid = (valid && error === '');
			temp[item.valueName] = error;
		}

		setResetPasswordInputErrors({
			email: temp.email,
			password: temp.password,
			passwordRepeat: temp.passwordRepeat
		});

		return valid;
	};

	if (resetStatus === 'success') {
		window.location.pathname = '/';
	}

	return (
		<div className={classes.loginBoxContainer}>
			<Paper className={classes.loginBox} elevation={0}>
				<div>
					<h4 className={classes.title}>Atjaunot paroli</h4>

					{errorMessage !== '' && <div className={classes.messageContainer}>
						{errorMessage !== '' && <ErrorMessage message={errorMessage} onClose={() => { dispatch(resetPasswordStatus()) }} />}
					</div>}
				</div>

				<InputForm
					formContent={resetPasswordContent}
					setFormInfo={setResetPasswordInfo}
					formInfo={resetPasswordInfo}
				/>

				<div className={classes.buttonContainer}>
					<MainButton disabled={resetStatus === 'started'} onClick={() => window.location.replace('/')}>Atpakaļ</MainButton>
					<MainButton disabled={resetStatus === 'started'} onClick={handleResetPassword}>Saglabāt</MainButton>
				</div>
			</Paper>
		</div>
	);
}

export default ResetPassword;
