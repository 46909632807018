import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';

const defaultState = {
	verifying: false,
	verifyError: '',
	verifyStatus: null,
};

export const verifyEmailSlice = createSlice({
	name: 'verifyEmail',
	initialState: defaultState,
	reducers: {
		verifyEmailSuccess: (state, action) => {
			state.verifyStatus = 'success';
			state.verifying = false;
		},
		verifyEmailFailure: (state, action) => {
			state.verifyError = action.payload;
			state.verifyStatus = 'idle';
			state.verifying = false;
		},
		verifyEmailAttemptStarted: (state, action) => {
			state.verifyError = '';
			state.verifyStatus = 'started';
			state.verifying = true;
		},
		resetStatus: (state, action) => {
			state.verifyError = '';
			state.verifyStatus = 'idle';
		},
	},
});

const { actions } = verifyEmailSlice;

export const verifyEmail = ({ id, token, expires, signature }) => (dispatch, getState) => {
	dispatch(actions.verifyEmailAttemptStarted());

	api.user.verifyEmail({ id, token, expires, signature })
		.then((resp) => resp.json())
		.then((resp) => {
			const { status } = resp;

			switch (status) {
				case 'success':
					dispatch(actions.verifyEmailSuccess());
					return;

				case 'error':
					dispatch(actions.verifyEmailFailure('Notika kļūda, mēģinot apstiprināt e-pasta adresi!'));
					return;

				default:
					dispatch(actions.verifyEmailFailure('Notika kļūda, mēģinot apstiprināt e-pasta adresi!'));
			}
		})
		.catch(() => {
			dispatch(actions.verifyEmailFailure('Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju'));
		});
};

export const resetVerifyStatus = () => (dispatch, getState) => {
	dispatch(actions.resetStatus());
};

export const verifySelectors = {
	isVerifying: state => state.verifyEmail.verifying,
	error: state => state.verifyEmail.verifyError,
	verifyStatus: state => state.verifyEmail.verifyStatus,
};

export default verifyEmailSlice.reducer;
