import { API_BASE } from '../config';

const serialize = (obj) =>
	'?' +
	Object.keys(obj)
		.reduce(function (a, k) {
			a.push(k + '=' + encodeURIComponent(obj[k]));
			return a;
		}, [])
		.join('&');

const formatURL = (url, params) => {
	let base = '';

	if (url.substr(0, 4) === 'http') {
		base = url;
	} else if (url.substr(0, 1) === '/') {
		base = url;
	} else if (API_BASE !== url.substr(0, API_BASE.length)) {
		base = API_BASE + url;
	}

	const queryString =
		typeof params === 'undefined' ||
		Object.keys(params).length === 0
			? ''
			: serialize(params);

	return `${base}${queryString}`;
};

const fetchResource = (path, userOptions = {}) => {
	const defaultOptions = {};
	const defaultHeaders = { Accept: 'application/json' };
	const bodyMethods = ['post', 'put', 'patch'];

	const options = {
		...defaultOptions,
		...userOptions,
		headers: {
			...defaultHeaders,
			...userOptions.headers,
		},
	};

	options.method = options.method.toLowerCase();

	const isFile = options.body instanceof File;

	// Stringify JSON data
	// If body is not a file
	if (
		options.body &&
		typeof options.body === 'object' &&
		!isFile &&
		bodyMethods.indexOf(options.method) > -1
	) {
		options.body = JSON.stringify(options.body);
	}

	const url = formatURL(path, options.params);

	return fetch(url, options);
};

const get = (path, params = {}) => {
	return fetchResource(path, { params, method: 'get' });
};

const post = (path, body = {}) => {
	return fetchResource(path, {
		body,
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
	});
};

const put = (path, body = {}) => {
	return fetchResource(path, {
		body,
		method: 'put',
		headers: { 'Content-Type': 'application/json' },
	});
};

const deleteMethod = (path, body = {}) => {
	return fetchResource(path, {
		body,
		method: 'delete',
		headers: { 'Content-Type': 'application/json' },
	});
};

export default fetchResource;

export { get, post, put, deleteMethod };
