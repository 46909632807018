import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Login from './login';
import Pricing from './pricing';
import Contacts from './contacts';
import About from './about';
import ResetPassword from './resetPassword';
import ContentSwitcher from './contentSwitcher';
import NotFound from './notFound';
import { Burtnieks } from './icons'

import { checkAuth, selectors, logout } from '../features/auth/slice';

import { makeStyles } from '@mui/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
	appContainer: {
		boxSizing: 'border-box',
		minHeight: '100%',
		padding: '40px 20px 55px 20px',
		minWidth: '100%',

		'@media (max-width: 850px)': {
			padding: '35px 20px',
		},
	},
	root: {
		flexGrow: 1,
		margin: 'auto',
		maxWidth: '1200px'
	},
	copyrightContainer: {
		alignItems: 'flex-start',
		bottom: '7px',
		display: 'flex',
		flexDirection: 'column',
		left: '10px',
		pointerEvents: 'none',
		position: 'absolute',
		userSelect: 'none',

		'@media (max-width: 850px)': {
			left: '20px',
		},
	},
	jsLogo: {
		cursor: 'pointer',
		height: '45px',
		pointerEvents: 'all',

		'@media (max-width: 850px)': {
			display: 'none',
		},
	},
	linkStyleOutline: {
		color: '#fff',
		cursor: 'pointer',
		filter: 'drop-shadow(0px 0px 1px #000)',
		fontSize: '0.8rem',
		fontWeight: '500',
		letterSpacing: '0.02857em',
		mozUserSelect: 'none',
		msUserSelect: 'none',
		paddingRight: '5px',
		pointerEvents: 'all',
		textDecoration: 'none',
		textShadow: '#000 0px 0px 1px',
		userSelect: 'none',
		webkitUserSelect: 'none',
	},
	header: {
		alignItems: 'flex-end',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '45px',

		'@media (max-width: 850px)': {
			alignItems: 'stretch',
			flexDirection: 'column',
			gap: '25px',
		},
	},
	logoPlaceholder: {
		height: '36px',

		'@media (max-width: 850px)': {
			display: 'none',
		},
	},
	logoContainer: {
		alignItems: 'flex-end',
		display: 'flex',
		flexDirection: 'row',
		gap: '15px',
		width: 'fit-content',

		'& h3': {
			color: '#000000',
			userSelect: 'none',
			textTransform: 'uppercase',
		},
	},
	logo: {
		height: 'auto',
		width: '36px',
	},
	navBar: {
		alignItems: 'center',
		display: 'flex',
		gap: '42px',
		justifyContent: 'flex-end',
		position: 'relative',
		zIndex: '1',

		'@media (max-width: 600px)': {
			alignItems: 'flex-end',
			flexDirection: 'column',
			gap: '8px',
		},
	},
	navButton: {
		color: '#000000',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '16px',
		fontWeight: '500',
		padding: '0',
		textDecoration: 'none',
		userSelect: 'none',

		'&:hover': {
			textDecoration: 'underline',
			textDecorationColor: '#4DCF10',
			textUnderlineOffset: '2px',
			textDecorationThickness: '2px',
		},
	},
	activeNavButton: {
		color: '#000000',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '16px',
		fontWeight: '500',
		padding: '0',
		textDecoration: 'underline',
		textDecorationColor: '#4DCF10',
		textUnderlineOffset: '2px',
		textDecorationThickness: '2px',
		userSelect: 'none',
	}
}));

const ErrorFallback = () => (
	<Typography component="div" style={{ padding: 8 }}>
		Notika kļūda.
	</Typography>
);

const AppComp = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const isAppReady = useSelector(selectors.isAppReady);
	const userIsLoggedIn = useSelector(selectors.isAuth);

	const [activeSection, setActiveSection] = useState(window.location.pathname);

	useEffect(() => {
		dispatch(checkAuth());
	}, []);

	if (!isAppReady) {
		return '';
	}

	const menuSections = [
		'/cena',
		'/kontakti',
		'/par',
		'/reset_password'
	];

	const getNavBar = () => {
		if (window.location.pathname === '/reset_password') {
			return (
				<div className={classes.header}>
					<div className={classes.logoContainer}>
						<Burtnieks className={classes.logo} />
						<h3>Burtnieks</h3>
					</div>
				</div>
			);
		}

		return (
			<div className={classes.header}>
				{!menuSections.includes(activeSection) ? (
					<div className={classes.logoPlaceholder} />
				) : (
					<div className={classes.logoContainer}>
						<Burtnieks className={classes.logo} />
						<h3>Burtnieks</h3>
					</div>
				)}

				<div className={classes.navBar}>
					<Link to="/" onClick={() => setActiveSection('/')} className={!menuSections.includes(activeSection) ? classes.activeNavButton : classes.navButton}>Sākums</Link>
					<Link to={menuSections[0]} onClick={() => setActiveSection(menuSections[0])} className={activeSection === menuSections[0] ? classes.activeNavButton : classes.navButton}>Cena</Link>
					<Link to={menuSections[1]} onClick={() => setActiveSection(menuSections[1])} className={activeSection === menuSections[1] ? classes.activeNavButton : classes.navButton}>Kontakti</Link>
					<Link to={menuSections[2]} onClick={() => setActiveSection(menuSections[2])} className={activeSection === menuSections[2] ? classes.activeNavButton : classes.navButton}>Par</Link>
				</div>
			</div>
		);
	}

	return (
		<div className={classes.appContainer}>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<div className={classes.root}>
					{userIsLoggedIn ? <ContentSwitcher />
						: (
							<BrowserRouter>
								{getNavBar()}

								<Routes>
									<Route exact path="/" element={<Login sectionChange={setActiveSection} />}></Route>
									<Route path={menuSections[0]} element={<Pricing />}></Route>
									<Route path={menuSections[1]} element={<Contacts />}></Route>
									<Route path={menuSections[2]} element={<About />}></Route>
									<Route path={menuSections[3]} element={<ResetPassword />}></Route>
									<Route path="*" element={<NotFound />}></Route>
								</Routes>
							</BrowserRouter>
						)
					}
				</div>

				<div className={classes.copyrightContainer}>
					<img className={classes.jsLogo} src="/images/logo.png" onClick={() => window.open('https://www.kartes.lv/', '_blank')} />
					<span className={classes.linkStyleOutline} onClick={() => window.open('https://www.kartes.lv/', '_blank')}>© Jāņa sēta, 2023</span>
				</div>
			</ErrorBoundary>
		</div>
	);
}

const App = () => {
	const dispatch = useDispatch();
	const isAppReady = useSelector(selectors.isAppReady);

	useEffect(() => {
		dispatch(checkAuth());
	}, []);

	if (!isAppReady) {
		return '';
	}

	return (
		<StyledEngineProvider injectFirst>
			<div>
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<AppComp />
				</ErrorBoundary>
			</div>
		</StyledEngineProvider>
	);
};

export default App;
