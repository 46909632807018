import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { setTrialPeriod } from '../app/slice';
import { getStatistics } from '../statistics/slice';

const defaultState = {
	creating: false,
	createError: '',
	createStatus: null,
	invoiceStarts: null,
	invoiceEnds: null,
	invoiceRenewed: null,
	invoicePaid: null,
};

export const createInvoiceSlice = createSlice({
	name: 'createInvoice',
	initialState: defaultState,
	reducers: {
		createInvoiceSuccess: (state, action) => {
			state.createStatus = 'success';
			state.creating = false;
		},
		createInvoiceFailure: (state, action) => {
			state.createError = action.payload;
			state.createStatus = 'idle';
			state.creating = false;
		},
		createInvoiceAttemptStarted: (state, action) => {
			state.createError = '';
			state.createStatus = 'started';
			state.creating = true;
		},
		resetStatus: (state, action) => {
			state.createError = '';
			state.createStatus = 'idle';
		},
		setInvoice: (state, action) => {
			const { invoiceStarts, invoiceEnds, invoiceRenewed, invoicePaid } = action.payload;

			state.invoiceStarts = invoiceStarts;
			state.invoiceEnds = invoiceEnds;
			state.invoiceRenewed = invoiceRenewed;
			state.invoicePaid = invoicePaid;
		},
	},
});

const { actions } = createInvoiceSlice;

export const createInvoice = () => (dispatch, getState) => {
	dispatch(actions.createInvoiceAttemptStarted());

	api.invoice.create()
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, data } = resp;

			if (status === 'error') {
				dispatch(actions.createInvoiceFailure('Notika kļūda, mēģinot izveidot rēķinu!'));
				return;
			}

			if (status === 'blocked') {
				dispatch(actions.createInvoiceFailure('Lietotāja konts ir bloķēts! Lai atbloķētu kontu, rakstiet uz support@kartes.lv!'));
				return;
			}

			if (status === 'success') {
				dispatch(actions.createInvoiceSuccess());
				dispatch(actions.setInvoice({ invoiceStarts: data.created_at, invoiceEnds: data.ends_at, invoiceRenewed: data.is_renewed, invoicePaid: data.is_paid }));
				dispatch(setTrialPeriod(false));
				dispatch(getStatistics());
			}
		})
		.catch(() => {
			dispatch(actions.createInvoiceFailure('Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju'));
		});
};

export const resetCreateInvoiceStatus = () => (dispatch, getState) => {
	dispatch(actions.resetStatus());
};

export const setInvoice = ({ invoiceStarts, invoiceEnds, invoiceRenewed, invoicePaid }) => (dispatch, getState) => {
	dispatch(actions.setInvoice({ invoiceStarts, invoiceEnds, invoiceRenewed, invoicePaid }));
};

export const createInvoiceSelectors = {
	isCreating: state => state.createInvoice.creating,
	error: state => state.createInvoice.createError,
	createStatus: state => state.createInvoice.createStatus,
	invoiceStarts: state => state.createInvoice.invoiceStarts,
	invoiceEnds: state => state.createInvoice.invoiceEnds,
	invoiceRenewed: state => state.createInvoice.invoiceRenewed,
	invoicePaid: state => state.createInvoice.invoicePaid,
};

export default createInvoiceSlice.reducer;
