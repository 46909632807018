import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	textBox: {
		backgroundColor: 'white',
		height: '100%',
		overflowY: 'auto',
		paddingRight: '10px',

		'& h2': {
			marginTop: '25px',
			marginBottom: '25px',
		},

		'& h3': {
			marginTop: '20px',
			marginBottom: '20px',
		},

		'& p': {
			marginBottom: '10px',
		},

		'& li': {
			listStyleType: 'disc',
			marginLeft: '20px'
		}
	}
}));

const OverlayText = () => {
	const classes = useStyles();

	return (
		<div className={classes.textBox}>
			<h2>End User License Agreement (EULA)</h2>
			<p>This End User License Agreement ("EULA") is a binding legal agreement between Jāņa sēta Ltd (the "Licensor") and you (the "Licensee") regarding the Service that you are accessing, using, downloading, installing and/or copying, including any accompanying manual, documentation or other related material provided by the Licensor as part of the Service, or in connection therewith.</p>
			<p>Please read this EULA carefully before accessing the solution and using the Burtnieks online service and related tools. It provides a license to use the Burtnieks service and contains warranty information and liability disclaimers.</p>
			<p>By clicking "accept" and/or accessing and/or using the Burtnieks service, you are confirming your acceptance of the terms of this EULA and agreeing to become bound by the terms of it.</p>
			<p>If you are entering into this EULA on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA, do not access or use the Service, and you must not accept this EULA.</p>
			<p>This EULA shall apply only to the online service supplied by the Licensor herewith regardless of whether other software/service is referred to or described herein. The terms also apply to any Licensor updates, supplements, Internet-based services, and support services for the Service, unless other terms accompany those items on delivery. If so, those terms apply.</p>

			<h3>DEFINITIONS</h3>
			<p>For the purposes of this EULA the following terms shall have the following meaning:</p>
			<p>Jāņa sēta SIA (Ltd) Jāņa sēta, a company duly incorporated and existing under the laws of Latvia with registration code 40003426448, and having its registered domicile in Krasta iela 105a, Riga, Latvia, LV-1019 (the "Licensor").</p>
			<p>Burtnieks - online place name translation tool solution with its tools provided on the website at the domain <i>burtnieks.kartes.lv</i>.</p>
			<p>Party - the Licensor and the Licensee jointly referred to as the "Parties" and separately as a "Party".</p>
			<p>EULA - this End User License Agreement.</p>
			<p>Portal - the website at the domain <i>burtnieks.kartes.lv</i> through which the Licensor provides, and the Licensee can access, order and use the Burtnieks Service(s).</p>
			<p>Service the Licensor provides access to the Portal and this all together is referred to as the "Service". Service also involves making available to the Licensee any user manuals, tools and other documents related to the Service.</p>
			<p>Data - database the Licensor uses to provide the Service. All rights (including but not limited to intellectual property rights) of the Data belong to the Licensor and/or its Suppliers.</p>
			<p>Supplier - the Licensors business partners or other third parties that provide partially or wholly the Data.</p>
			<p>Server - the server of the Licensor or a third party server hosting Licensors Database (accessible via the Portal) which is set up and maintained for delivering Burtnieks Service to the Licensee by granting the Licensee remote online access to the Database via the Services.</p>
			<p>Intellectual Property Rights (IP Rights) - shall mean all and any copyrights and database maker’s rights in the Database, the Server, the Portal or any other component thereof protected by such rights (the "Works") as well as in any results of the intermediate stages of developing any of the Works, including, but not limited to, the right to reproduce, distribute, improve, supplement and/or make amendments to the Works, and all other intellectual property that may now or in the future subsist in any part of the world, including, but not limited to, the rights in inventions (e.g. patents and utility models), trademarks, business names, know-how and trade secrets related to the Service.</p>
			<p>Licensee - visitor at the website <i>burtnieks.kartes.lv</i> or/and user of services provided at the Portal.</p>
			<p>License fee – fee required from Licensee to become a user of <i>Burtnieks</i> for 90 day subscription period.</p>
			<p>Yearly license fee – fee required from Licensee to become a user of <i>Burtnieks</i> for unlimited 1 year subscription period.</p>
			<p>Additional fee – additional payment that Licensee is obliged to pay in case if more than 1500 translations are made during subscription period. Additional fee is 0,05 EUR + 21% VAT per successful translation request.</p>
			<p>Translation request - measurement unit of succesful translations in Burtnieks platform, translation request is counted as succesful if Licensee has received translation for requested place name.</p>
			<p>Subscription period – 90 day subscription period that allows user to use full functionality of Burtnieks platform. Subscription period can be activated by payment of License fee.</p>
			<p>Yearly subscription – 1 year subscription period that allows user to use full functionality of Burtnieks platform without usage limitations.</p>

			<h3>LICENSE GRANT</h3>
			<p>The Licensor hereby grants the Licensee a personal, non-transferable, non-exclusive license to use the Burtnieks service on Licensee’s devices in accordance with the terms of this EULA.</p>
			<p>The license is valid worldwide. However, it is the Licensee’s obligation and responsibility to always check and make sure that the activities which the license is used for are permitted under the applicable laws of the respective jurisdiction, where the license is used, and the Licensee shall indemnify and hold the Licensor harmless from any claim and liability arising from any such activities.</p>
			<p>The Licensee is permitted to access and use the Burtnieks solution and tools in devices (for example a PC, laptop, mobile or tablet) under Licensee’s control. Licensee is responsible for ensuring that Licensee’s devices meet the minimum requirements of the Burtnieks service. After registration and acceptance of this EULA Licensee is permitted to make 20 translation requests for free. In case of more than 20 translation requests Licensee becomes the subject of License fee (see more at section "LICENSE FEE" below).</p>
			<p>To access unlimited Burtnieks usage without limitations of 90 day subscription period, Licensee can apply to for a Yearly subscription period by writting request to <i>support@kartes.lv</i></p>
			<p>The Licensee is not permitted to:</p>
			<ul>
				<li>Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Burtnieks service nor permit the whole or any part of the Service to be combined with or become incorporated in any other services, nor decompile, disassemble or reverse engineer the Service or attempt to do any such things.</li>
				<li>Use the Service in any way that violates any applicable national or international law or regulation.</li>
				<li>Use the Service for the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
				<li>Transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.</li>
				<li>Impersonate or attempt to impersonate Licensor, a Licensor employee, another user, or any other person or entity.</li>
				<li>Use the Service in any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</li>
				<li>Engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Licensor or users of Service or expose them to liability.</li>
				<li>Use the Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.</li>
				<li>Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</li>
				<li>Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</li>
				<li>Use any device, software, or routine that interferes with the proper working of Service.</li>
				<li>Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</li>
				<li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</li>
				<li>Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</li>
				<li>Take any action that may damage or falsify Licensor’s rating.</li>
				<li>Otherwise attempt to interfere with the proper working of Service.</li>
				<li>Use the Service for any purpose that the Licensor considers is a breach of this EULA.</li>
			</ul>

			<h3>INTELLECTUAL PROPERTY</h3>
			<p>Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Licensor and its licensors. Service is protected by copyright, trademark, and other laws of the respective countries of the Licensor. Our trademarks may not be used in connection with any product or service without the prior written consent of Licensor.</p>

			<h3>COPYRIGHT POLICY</h3>
			<p>Licensor does not restrict the usage of the files consisting of work done within the Service. The responsibility for them lies solely with the Licensee.</p>

			<h3>LICENSE FEE</h3>
			<p>Yearly license fee (the "Yearly license fee") is 300.00 EUR including  Value Added Tax (VAT) for subscription period of 1 year.</p>
			<p>Quarterly license fee (the "License Fee") is 30.00 EUR including Value Added Tax (VAT) for 90 day subscription period (the "Subscription period"). License Fee covers 1500 translation requests during the Subscription period. If none of the Parties have terminated the subscription during Subscription period, at the end of Subscription period new invoice will be sent to Licensee and new Subscription period will be started. Subscription periods can be renewed for unlimited amount of times until any of Parties terminate the subscription.</p>
			<p>In case Licensee requests more than 1500 translations within Subscription period, Additional fee (the "Additional fee") of 0,05 EUR (+ 21% VAT per translation) is applied. Additional fee is added to invoice for following Subscription period, or, in case of termination, by separate invoice. Together with the invoice, the Licensor shall present to the Licensee the amount of data processed by the Burtnieks service and the corresponding total cost.</p>
			<p>The validity of the License depends on the due payment of the License Fee. If the Licensee has delayed the payment of the License Fee for more than 7 days or if it is not defined otherwise in the contract between the Licensee and Licensor the Licensor has the right to restrict and/or prevent the use of the Service by the Licensee until full payment of the debt.</p>
			<p>Regular updates and new releases of the Service are included in the License Fee.</p>
			<p>The Licensor undertakes to ensure the availability of the Service in accordance with this EULA. However, additional maintenance or support for the implementation which is not on Licensor’s infrastructure is not included in the License Fee and the Licensor shall have no obligation to provide such services under this EULA.</p>
			<p>The valid price list is published on the Portal. The Licensor will notify the Licensee about a change in the price list at least 30 days in advance. Changes in the price list are not applicable for service subscription that Licensee has already pre-payed.</p>

			<h3>INVOICING</h3>
			<p>The invoice will be sent to Licensee’s email within 72 hours after request from Licensee or within 96 hours after Licensee has made more than 20 translation requests. Invoice must be paid within 5 days after invoice issue date. If Parties have not agreed differently, invoice cover the cost of 1 Subscription period and, if applicable, Additonal fee for overdue of translation requests in previous Subscription period.</p>

			<h3>LIABILITY</h3>
			<p>To the extent permitted by applicable law, the Licensor shall not be liable for any indirect, incidental, special, punitive, or consequential damages, or any loss of profits, revenue, data, or data use.</p>
			<p>To the extent permitted by applicable law, the Licensor shall not be liable for any errors contained in the Data, or for any incompatibility of the Data with the Licensee’s specific purposes.</p>
			<p>The Licensor shall not be liable for the use of the Data and the Service in countries where the use of such data or Services is prohibited or requires special permission.</p>
			<p>Non-performance by the Party is excused if it is caused by force majeure. Force majeure are circumstances which are beyond the control of the Party and which, at the time the EULA was entered into, the Party could not reasonably have been expected to take into account, avoid or overcome the impediment or the consequences thereof which the Party could not reasonably have been expected to overcome.</p>

			<h3>ERROR REPORTING AND FEEDBACK</h3>
			<p>Licensee may provide Licensor either directly at <i>support@kartes.lv</i> or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service ("Feedback"). Licensee acknowledges and agrees that: (i) Licensee shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Licensor may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from Licensee or any third party; and (iv) Licensor is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, Licensee grants Licensor and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</p>

			<h3>DISCLAIMER OF WARRANTY</h3>
			<p>The Service is provided by Licensor on an "as is" and "as available" basis. Licensor makes no representations or warranties of any kind, express or implied, as to the operation of the Service, or the information, content or materials included therein. Licensee expressly agrees that use of the Service, its content, and any services or items obtained from Licensor is at Licensee’s sole risk.</p>
			<p>Neither Licensor nor any person associated with Licensor makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the Service. Without limiting the foregoing, neither Licensor nor anyone associated with Licensor represents or warrants that the Service, its content, or any services or items obtained through the Service will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected, that the Service or the server that makes it available is free of viruses or other harmful components or that the Service or any services or items obtained through the Service will otherwise meet Licensee’s needs or expectations.</p>
			<p>Licensor hereby disclaims all warranties of any kind, whether expressed or implied, statutory, or otherwise, including but not limited to any warranties of merchantability, non-infringement, and fitness for particular purpose.</p>
			<p>The foregoing does not affect any warranties which cannot be excluded or limited under applicable law.</p>

			<h3>TERMINATION</h3>
			<p>Licensor may terminate or suspend Licensee’s account and ban access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of EULA.</p>
			<p>If Licensee wishes to terminate their account or terminate their subscription, Licensee may send request to <i>support@kartes.lv</i>. In case of termination Licensee does not have rights to request refund for remaining time of Subscription. Termination of agreement for subscription can be made not later than 5 days before the start of new subscription period.</p>
			<p>All provisions of EULA which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

			<h3>GOVERNING LAW</h3>
			<p>The EULA shall be governed and construed in accordance with the laws of Latvia, the governing law of which applies to agreement without regard to its conflict of law provisions.</p>
			<p>Licensor’s failure to enforce any right or provision of the EULA will not be considered a waiver of those rights. If any provision of the EULA is held to be invalid or unenforceable by a court, the remaining provisions of the EULA will remain in effect. These Terms constitute the entire EULA between Parties regarding the Service and supersede and replace any prior agreements Parties might have had regarding Service.</p>
			<p>Any dispute, controversy or claim arising out of or relating to this EULA, or the breach, termination or validity thereof shall be finally settled by courts of Latvia under the general rules of the Civil Procedure Law of Latvia.</p>

			<h3>CHANGES TO SERVICE</h3>
			<p>Licensor reserves the right to withdraw or amend the Service, and any service or material Licensor provides via Service, in Licensor’s sole discretion without notice. Licensor will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, Licensor may restrict access to some parts of Service, or the entire Service, to users, including registered users.</p>

			<h3>AMENDMENTS TO TERMS</h3>
			<p>Licensor may amend Terms at any time by posting the amended terms on this site. It is Licensee’s responsibility to review these Terms periodically.</p>
			<p>Licensee’s continued use of the Service following the posting of revised Terms means that Licensee accepts and agrees to the changes.</p>
			<p>By continuing to access or use our Service after any revisions become effective, Licensee agrees to be bound by the revised terms. If Licensee does not agree to the new terms, the Licensee is no longer authorized to use Service.</p>

			<h3>WAIVER AND SEVERABILITY</h3>
			<p>No waiver by Licensor of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Licensor to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</p>
			<p>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</p>

			<h3>ACKNOWLEDGEMENT</h3>
			<p>By using Service or other services provided by Licensor, Licensee acknowledges that Licensee has read this EULA and agrees to be bound by it.</p>

			<h3>CONTACT US</h3>
			<p>Please send your feedback, comments, requests for technical support by email <i>support@kartes.lv</i>.</p>
		</div>
	);
}

export default OverlayText;
