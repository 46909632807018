import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';

const defaultState = {
	reseting: false,
	resetError: '',
	resetStatus: null,
};

export const resetPasswordSlice = createSlice({
	name: 'resetPassword',
	initialState: defaultState,
	reducers: {
		resetPasswordSuccess: (state, action) => {
			state.resetStatus = 'success';
			state.reseting = false;
		},
		resetPasswordFailure: (state, action) => {
			state.resetError = action.payload;
			state.resetStatus = 'idle';
			state.reseting = false;
		},
		resetPasswordAttemptStarted: (state, action) => {
			state.resetError = '';
			state.resetStatus = 'started';
			state.reseting = true;
		},
		resetStatus: (state, action) => {
			state.resetError = '';
			state.resetStatus = 'idle';
		},
	},
});

const { actions } = resetPasswordSlice;

export const resetPassword = ({ email, password, password_confirmation, token }) => (dispatch, getState) => {
	dispatch(actions.resetPasswordAttemptStarted());

	api.user.resetPassword({ email, password, password_confirmation, token })
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, user } = resp;

			switch (status) {
				case 'error':
					dispatch(actions.resetPasswordFailure('Ievadītais e-pasts nesakrīt ar paroles atjaunošanas e-pasta saņēmēju!'));
					return;

				case 'blocked':
					dispatch(actions.resetPasswordFailure('Lietotāja konts ir bloķēts! Lai atbloķētu kontu, rakstiet uz support@kartes.lv!'));
					return;

				case 'token':
					dispatch(actions.resetPasswordFailure('Paroles atkopšanas laiks ir pagājis vai parole jau tikusi atkopta. Nepieciešamības gadījumā atkārtojiet paroles atkopšanu!'));
					return;

				default:
					dispatch(actions.resetPasswordSuccess());
			}
		})
		.catch(() => {
			dispatch(actions.resetPasswordFailure('Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju'));
		});
};

export const resetPasswordStatus = () => (dispatch, getState) => {
	dispatch(actions.resetStatus());
};

export const resetSelectors = {
	isReseting: state => state.resetPassword.reseting,
	error: state => state.resetPassword.resetError,
	resetStatus: state => state.resetPassword.resetStatus,
};

export default resetPasswordSlice.reducer;
