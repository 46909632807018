import { get, post, put } from '../api';

const user = {
	changePassword: async ({ old_password, password }) => {
		return put('user/password', { old_password, password });
	},
	forgotPassword: async ({ email }) => {
		return post('forgot_password', { email });
	},
	metadata: async ({ name, metadata, tos_accepted }) => {
		return put('user', { name, metadata, tos_accepted });
	},
	register: async ({ name, email, password }) => {
		return post('register', { name, email, password });
	},
	resetPassword: async ({ email, password, password_confirmation, token }) => {
		return post('reset_password', { email, password, password_confirmation, token });
	},
	status: async () => {
		return get('user');
	},
	verifyEmail: async ({ id, token, expires, signature }) => {
		return get('email/verify/' + id + '/' + token, { expires, signature });
	},
};

export default user;
