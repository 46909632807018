import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { getStatistics } from '../statistics/slice';

const defaultState = {
	dataRequestActive: false,
	dataError: '',
	response: [],
	suggestionRequestActive: false,
	suggestions: []
};

export const dataSlice = createSlice({
	name: 'data',
	initialState: defaultState,
	reducers: {
		dataRequestSuccess: (state, action) => {
			state.dataRequestActive = false;
		},
		dataRequestFailure: (state, action) => {
			state.dataRequestActive = false;
			state.dataError = action.payload;
		},
		dataRequestAttemptStarted: (state, action) => {
			state.dataRequestActive = true;
			state.dataError = '';
		},
		setResponse: (state, action) => {
			const { response } = action.payload;
			state.response = response;
		},
		clearResponse: (state, action) => {
			const { response } = [];
			state.response = response;
		},
		suggestionRequestAttemptStarted: (state, action) => {
			state.suggestionRequestActive = true;
		},
		setSuggestions: (state, action) => {
			state.suggestionRequestActive = false;

			const { suggestions } = action.payload;
			state.suggestions = suggestions;
		},
		clearSuggestions: (state, action) => {
			state.suggestionRequestActive = false;

			const { suggestions } = [];
			state.suggestions = suggestions;
		},
	},
});

const { actions } = dataSlice;

export const requestData = ({ lang, query }) => (dispatch, getState) => {
	dispatch(actions.dataRequestAttemptStarted());

	api.data.request({ lang, query })
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, data } = resp;
			if (status === 'error') {
				dispatch(actions.dataRequestFailure('Ievadīti nepareizi dati'));
				return;
			}

			if (status === 'blocked') {
				dispatch(actions.dataRequestFailure('Lietotāja konts ir bloķēts! Lai atbloķētu kontu, rakstiet uz support@kartes.lv!'));
				return;
			}

			dispatch(actions.dataRequestSuccess());
			dispatch(actions.setResponse({ response: data.features }));
			dispatch(getStatistics());
		})
		.catch(() => {
			dispatch(actions.dataRequestFailure('Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju'));
		});
};

export const requestSuggestions = ({ query, lang }) => (dispatch, getState) => {
	dispatch(actions.suggestionRequestAttemptStarted());

	api.suggest.request({ query, lang })
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, data } = resp;

			if (status === 'error' || status === 'blocked') {
				dispatch(actions.clearSuggestions());
				return;
			}

			dispatch(actions.setSuggestions({ suggestions: resp.data }));
		})
		.catch(() => {
			dispatch(actions.clearSuggestions());
		});
};

export const clearRequestData = () => (dispatch, getState) => {
	dispatch(actions.clearResponse());
};

export const clearSuggestionData = () => (dispatch, getState) => {
	dispatch(actions.clearSuggestions());
};

export const dataSelectors = {
	requestSuccessful: state => (!state.data.dataRequestActive && state.data.dataError === ''),
	requestFailed: state => (!state.data.dataRequestActive && state.data.dataError !== ''),
	requestActive: state => (state.data.dataRequestActive && state.data.dataError === ''),
	request: state => state.data.response,
	suggestionRequestActive: state => state.data.suggestionRequestActive,
	suggestions: state => state.data.suggestions,
};

export default dataSlice.reducer;
