import { createSlice } from '@reduxjs/toolkit';

export const notifierSlice = createSlice({
	name: 'notifier',
	initialState: {
		notifications: [],
	},
	reducers: {
		enqueue: (state, action) => {
			state.notifications.push(action.payload)
		},
		close: (state, action) => {
			state.notifications = state.notifications.map(notification => (action.payload.dismissAll || notification.key === action.payload.key)
				? { ...notification, dismissed: true }
				: { ...notification });
		},
		remove: (state, action) => {
			state.notifications = state.notifications.filter(
				notification => notification.key !== action.payload.key,
			)
		},
	}
});

const { actions } = notifierSlice;

const selectors = {
	notifications: state => state.notifier.notifications
}

const enqueueToSnack = ({ message, variant }) => (dispatch) => {
	const key = new Date().getTime() + Math.random();
	dispatch(actions.enqueue({
		message,
		options: {
			key,
			variant
		},
	}))
}

export { actions, selectors, enqueueToSnack };

export default notifierSlice.reducer;
