import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import store from './features/store';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import theme from './theme';

require('./style.css');

render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={3}>
				<App />
			</SnackbarProvider>
		</ThemeProvider>
	</Provider>,
	document.getElementById('app'),
);
