import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		action: {
			hover: { main: '#00264A' },
			hoverText: { main: '#fff' },
		},
		primary: { main: '#3360C2' }, // done
		secondary: { main: '#00264A' },
		success: { main: '#deefd7' },
		gray: { main: '#E7E7E7' },
		lightGray: { main: '#949798' },
		darkGray: { main: '#333' },
		text: { main: '#2B2E30' },
		blue: { main: '#3360C2' },
		red: { main: '#ff5630' },
		whitish: { main: '#f5f5f5' },
		border: { main: '#95989A' },
		selected: { main: '#B3D5F4' },
		icon: { main: '#777777' },
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					textTransform: 'none',
				},
				outlinedPrimary: {
					backgroundColor: 'white',
					borderColor: '#3360C2',
					color: '#3360C2',
					borderWidth: '2px',
					'&:hover': {
						borderWidth: '2px',
						backgroundColor: '#3360C2',
						color: 'white',
					}
				},
				textPrimary: {
					color: '#3360C2',
					'&:hover': {
						color: 'white',
					}
				}
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				colorSecondary: {
					color: '#3360C2',
					'&.Mui-checked': {
						color: '#3360C2',
					},
				},
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: '#F5F5F5',
				}
			}
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					borderBottom: '2px solid #E7E7E7',
					'&:last-child': {
						paddingBottom: 0,
					},
				}
			}
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					padding: '3px',
				}
			}
		},
		MuiChip: {
			styleOverrides: {
				root: {
					borderRadius: '7px',
					height: '26px',
				}
			}
		},
	},
});

export default theme;
