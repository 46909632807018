import React from 'react';

import { TextButtonNoPadding } from '../buttons';

import { makeStyles } from '@mui/styles';
import { TextField, Checkbox, IconButton, InputAdornment, FormControl, Alert } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles((theme) => ({
	textBox: {
		'& .MuiOutlinedInput-input': {
			padding: '5px',
		},

		'& .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #000000',
			borderRadius: '2px',
		},

		'& .MuiInputAdornment-root': {
			position: 'absolute',
			right: '0',
		},

		'& .MuiOutlinedInput-adornedEnd': {
			padding: '0',
		}
	},
	formContainer: {
		width: '100%',
	},
	formRow: {
		display: 'flex',
		gap: '20px',
		margin: '3px 0',
		width: '100%',

		'&:first-of-type': {
			marginTop: '0',
		},

		'@media (max-width: 850px)': {
			flexDirection: 'column',
			gap: '6px',
		},
	},
	textFormRow: {
		display: 'flex',
		gap: '20px',
		margin: '6px 0',
		width: '100%',

		'&:first-of-type': {
			marginTop: '0',
		},
	},
	formRowLabel: {
		alignItems: 'flex-start',
		color: '#847D6F',
		display: 'flex',
		fontWeight: '600',
		fontSize: '14px',
		justifyContent: 'flex-end',
		marginRight: '5px',
		paddingTop: '4px',
		textAlign: 'right',
		whiteSpace: 'nowrap',
		width: '28%',

		'@media (max-width: 850px)': {
			justifyContent: 'flex-start',
			textAlign: 'left',
			width: '100%',
		},
	},
	formRowInput: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		paddingRight: '25px',
		width: '72%',

		'& .MuiInputBase-input': {
			color: '#000000',
			fontFamily: 'Raleway, Arial, sans-serif',
			fontSize: '16px',
			fontWeight: '500',
		},

		'& .MuiInputBase-adornedEnd': {
			padding: '0',
		},

		'& .MuiTextField-root': {
			width: '100%',
		},

		'@media (max-width: 850px)': {
			paddingRight: '0',
			width: '100%',
		},
	},
	formRowText: {
		marginTop: '3px',
	},
	formRowEULA: {
		display: 'flex',
		flexDirection: 'row',
	},
	formColumn: {
		display: 'flex',
		flexDirection: 'column',

		'@media (min-width: 500px) and (max-width: 850px)': {
			alignItems: 'center',
			flexDirection: 'row',
			gap: '3px',
		},
	},
	formRowCheckbox: {
		display: 'flex',
		gap: '10px',
		marginRight: '4px',

		'& .MuiCheckbox-root:hover': {
			backgroundColor: 'unset',
		},

		'& .MuiCheckbox-root, .Mui-checked': {
			color: '#000000',
			padding: '0',
		},

		'@media (max-width: 850px)': {
			marginRight: '2px',
		},
	},
	checkboxText: {
		color: '#847D6F',
		cursor: 'pointer',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '500',
		lineHeight: '18px',
		userSelect: 'none',
	},
	inputError: {
		borderRadius: '0',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '13px',
		fontWeight: '600',
		marginTop: '5px',
		padding: '5px',

		'& .MuiAlert-message': {
			padding: '0',
		}
	},
}));

export const InputForm = ({ formContent, setFormInfo, formInfo }) => {
	const classes = useStyles();
	let key = 0;

	return (
		<FormControl component="form" className={classes.formContainer}>
			{formContent.map(item => (
				<div key={key++} className={classes.formRow}>
					<div className={classes.formRowLabel}>
						{item.label}{item.label.length > 0 ? ':' : ''}
					</div>

					{item.valueType !== 'checkbox' &&
						<div className={classes.formRowInput}>
							<TextField
								type={!('visible' in item) || item.visible ? item.valueType === 'email' ? 'email' : 'text' : 'password'}
								value={item.value}
								onChange={(event) => setFormInfo(formInfo => ({ ...formInfo, ...{ [item.valueName]: event.target.value } }))}
								variant="outlined"
								className={classes.textBox}
								autoComplete="off"
								InputProps={!('visible' in item) ? {} : {
									endAdornment:
									<InputAdornment position="end">
										<IconButton
											onClick={() => setFormInfo(formInfo => ({ ...formInfo, ...{ [item.visibleName]: !item.visible } }))}
											size="large">
											{item.visible ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>,
								}}
							/>

							{item.errors !== '' && <Alert className={classes.inputError} icon={false} severity="error">{item.errors}</Alert>}
						</div>
					}

					{item.valueType === 'checkbox' &&
						<div className={classes.formRowInput}>
							<div className={classes.formRowEULA}>
								<div className={classes.formRowCheckbox} onClick={item.primaryFunction}>
									<Checkbox checked={item.value} name="tosAccepted" />
								</div>

								<div className={classes.formColumn}>
									<span className={classes.checkboxText} onClick={item.primaryFunction}>Esmu izlasījis un iepazinies ar</span>
									<TextButtonNoPadding onClick={() => item.secondaryFunction(true)}>galalietotāja licences līgumu (EULA)!</TextButtonNoPadding>
								</div>
							</div>

							{item.errors !== '' && <Alert className={classes.inputError} icon={false} severity="error">{item.errors}</Alert>}
						</div>
					}
				</div>
			))}
		</FormControl>
	);
};

export const ShowForm = ({ formContent }) => {
	const classes = useStyles();
	let key = 0;

	return (
		<FormControl component="div" className={classes.formContainer}>
			{formContent.map(item => (
				<div key={key++} className={classes.textFormRow}>
					<div className={classes.formRowLabel}>
						{item.label}:
					</div>

					<div className={classes.formRowText}>
						{item.value}
					</div>
				</div>
			))}
		</FormControl>
	);
};
