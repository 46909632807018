import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { setUser } from '../app/slice';

const defaultState = {
	changing: false,
	changeError: '',
	changeStatus: null
};

export const changeMetadataSlice = createSlice({
	name: 'metadata',
	initialState: defaultState,
	reducers: {
		changeMetadataSuccess: (state, action) => {
			state.changeStatus = 'success';
			state.changing = false;
		},
		changeMetadataFailure: (state, action) => {
			state.changeError = action.payload;
			state.changeStatus = 'idle';
			state.changing = false;
		},
		changeMetadataAttemptStarted: (state, action) => {
			state.changeError = '';
			state.changeStatus = 'started';
			state.changing = true;
		},
		resetStatus: (state, action) => {
			state.changeError = '';
			state.changeStatus = 'idle';
		},
	},
});

const { actions } = changeMetadataSlice;

export const changeMetadata = ({ name, metadata, tos_accepted = null }) => (dispatch, getState) => {
	dispatch(actions.changeMetadataAttemptStarted());

	api.user.metadata({ name, metadata, tos_accepted })
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, data } = resp;

			switch (status) {
				case 'success':
					dispatch(setUser({
						userId: data.id,
						admin: data.is_admin,
						username: data.name,
						email: data.email,
						email_verified_at: data.email_verified_at,
						metadata: data.metadata,
						tos_accepted: data.tos_accepted,
						created_at: data.created_at
					}));
					dispatch(actions.changeMetadataSuccess());
					return;

				case 'blocked':
					dispatch(actions.changeMetadataFailure('Lietotāja konts ir bloķēts! Lai atbloķētu kontu, rakstiet uz support@kartes.lv!'));
					return;

				default:
					dispatch(actions.changeMetadataFailure('Informāciju saglabāt neizdevās. Mēģiniet vēlreiz pēc kāda laika!'));
			}
		})
		.catch(() => {
			dispatch(actions.changeMetadataFailure('Informāciju saglabāt neizdevās. Mēģiniet vēlreiz pēc kāda laika!'));
		});
};

export const resetMetadataStatus = () => (dispatch, getState) => {
	dispatch(actions.resetStatus());
};

export const changeMetadataSelectors = {
	isChanging: state => state.metadata.changing,
	error: state => state.metadata.changeError,
	changeStatus: state => state.metadata.changeStatus,
};

export default changeMetadataSlice.reducer;
