import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { MainButton } from '../buttons';
import { SuccessMessage, ErrorMessage } from '../messages';
import { verifyEmail, verifySelectors } from '../../features/verifyEmail/slice';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	loginBoxContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'center',
		left: '0',
		position: 'absolute',
		top: '0',
		width: '100%',

		'@media (max-width: 850px)': {
			left: 'auto',
			width: 'calc(100% - 40px)',
		},
	},
	buttonContainer: {
		display: 'flex',
		gap: '30px',
		justifyContent: 'center',
		marginTop: '24px',
	},
}));

const VerifyEmail = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const verifyStatus = useSelector(verifySelectors.verifyStatus);
	const errorMessage = useSelector(verifySelectors.error);

	useEffect(() => {
		const id = params.id;
		const token = params.token;
		const expires = searchParams.get('expires');
		const signature = searchParams.get('signature');

		dispatch(verifyEmail({ id, token, expires, signature }));
	}, []);

	return (
		<div className={classes.loginBoxContainer}>
			{(verifyStatus === 'success' || errorMessage !== '') && <div className={classes.messageContainer}>
				{verifyStatus === 'success' && <SuccessMessage message="E-pasta adrese tika veiksmīgi apstiprināta!" />}
				{errorMessage !== '' && <ErrorMessage message={errorMessage} />}
			</div>}

			<div className={classes.buttonContainer}>
				<MainButton disabled={!(verifyStatus === 'success' || errorMessage !== '')} onClick={() => { window.location.href = '/' }}>Uz "Burtnieku"</MainButton>
			</div>
		</div>
	);
}

export default VerifyEmail;
