import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';

const defaultState = {
	changing: false,
	changeError: '',
	changeStatus: null,
};

export const changePasswordSlice = createSlice({
	name: 'changePassword',
	initialState: defaultState,
	reducers: {
		changePasswordSuccess: (state, action) => {
			state.changeStatus = 'success';
			state.changing = false;
		},
		changePasswordFailure: (state, action) => {
			state.changeError = action.payload;
			state.changeStatus = 'idle';
			state.changing = false;
		},
		changePasswordAttemptStarted: (state, action) => {
			state.changeError = '';
			state.changeStatus = 'started';
			state.changing = true;
		},
		resetStatus: (state, action) => {
			state.changeError = '';
			state.changeStatus = 'idle';
		},
	},
});

const { actions } = changePasswordSlice;

export const changePassword = ({ old_password, password }) => (dispatch, getState) => {
	dispatch(actions.changePasswordAttemptStarted());

	api.user.changePassword({ old_password, password })
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, user } = resp;

			if (status === 'error') {
				dispatch(actions.changePasswordFailure('Ievadītā parole nesakrīt ar esošo paroli!'));
				return;
			}

			if (status === 'blocked') {
				dispatch(actions.changePasswordFailure('Lietotāja konts ir bloķēts! Lai atbloķētu kontu, rakstiet uz support@kartes.lv!'));
				return;
			}

			// if (status === 'success') {
				dispatch(actions.changePasswordSuccess());
			// }
		})
		.catch(() => {
			dispatch(actions.changePasswordFailure('Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju'));
		});
};

export const resetPasswordStatus = () => (dispatch, getState) => {
	dispatch(actions.resetStatus());
};

export const changePasswordSelectors = {
	isChanging: state => state.changePassword.changing,
	error: state => state.changePassword.changeError,
	changeStatus: state => state.changePassword.changeStatus,
};

export default changePasswordSlice.reducer;
