import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	textBox: {
		width: '500px',

		'& p': {
			marginBottom: '25px',
		},

		'@media (max-width: 850px)': {
			width: '100%',
		},
	}
}));

const Pricing = () => {
	const classes = useStyles();

	return (
		<div className={classes.textBox}>
			<p>Platforma "Burtnieks" pieejama, izmantojot abonēšanas modeli. Pieejami 2 abonēšanas modeļi – <strong>ceturkšņa abonements</strong> un <strong>gada abonements</strong>.</p>

			<p><strong>Ceturkšņa abonementa</strong> cena ir 30,00 EUR par 90 dienu abonēšanas periodu, cenā iekļauti 1500 tulkojuma pieprasījumi. Pārsniedzot 1500 pieprasījumu robežu tiek aprēķināta papildus maksa – 0,06 EUR par katru nākamo tulkojuma pieprasījumu. 1500 pieprasījumu robežas pārsniegšanas gadījumā papildus maksa tiek aprēķināta abonēšanas perioda beigās un tiek pievienota nākamajam abonēšanas rēķinam. Gadījumā, ja klients atsakās no turpmākas pakalpojuma abonēšanas, rēķins par papildus pieprasījumiem tiek izsūtīts atsevišķi.</p>
			<p><strong>Gada abonementa</strong> cena ir 300.00 EUR par 1 gada abonēšanas periodu, bez tulkojuma pieprasījumu limita.</p>

			<p>Jauniem "Burtnieka" lietotājiem ir iespēja iepazīties ar platformu, veicot 20 tulkojuma pieprasījumus bez maksas.</p>

			<p>Platformas lietošanas noteikumus regulē galalietotāja licences līgums <strong>(EULA)</strong>. Papildu jautājumus par platformas darbību un maksājumu kārtību iespējams noskaidrot, sazinoties ar mums e-pastā <a href="mailto:support@kartes.lv">support@kartes.lv</a>.</p>
		</div>
	);
}

export default Pricing;
