import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { createInvoiceSelectors } from '../../features/createInvoice/slice';
import { getStatisticsSelectors } from '../../features/statistics/slice';
import { appSelectors } from '../../features/app/slice';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const useStyles = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
	},
	resultsList: {
		height: '317px',
		padding: '0',
		overflowY: 'auto',
	},
	table: {
		minWidth: '100%',
	},
	tableHead: {
		borderBottom: '1px solid #000000',
		color: '#847D6F',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '600',
	},
	tableCell: {
		borderBottom: '1px solid #000000',
		color: '#000000',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '16px',
		fontWeight: '500',
	},
	tablePagination: {
		color: '#000000',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '16px',
		fontWeight: '500',

		'& .MuiInputBase-root': {
			color: '#000000',
			fontFamily: 'Raleway, Arial, sans-serif',
			fontSize: '14px',
			fontWeight: '500',
		},

		'& p': {
			color: '#847D6F',
			fontFamily: 'Raleway, Arial, sans-serif',
			fontSize: '14px',
			fontWeight: '600',
		},
	},
	paymentStatisticsContainer: {
		color: '#000000',
		display: 'flex',
		flexDirection: 'column',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '16px',
		fontWeight: '500',
		gap: '4px',
		marginBottom: '10px',
	},
	statisticsTitle: {
		color: '#847D6F',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '600',
	},
	statisticsNumber: {
		color: '#000000',
	},
}));

function TablePaginationActions (props) {
	const classes = useStyles();
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
				size="large">
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
				size="large">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
				size="large">
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
				size="large">
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

const Statistics = () => {
	const classes = useStyles();

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const statistics = [...useSelector(getStatisticsSelectors.statistics)].sort((a, b) => (a.date > b.date ? -1 : 1));
	const recentStatistics = useSelector(getStatisticsSelectors.recentStatistics);
	const trialPeriod = useSelector(appSelectors.trialPeriod);
	const invoiceStarts = useSelector(createInvoiceSelectors.invoiceStarts);
	const invoiceEnds = useSelector(createInvoiceSelectors.invoiceEnds);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const periodDates = invoiceEnds ? new Date(invoiceStarts).toLocaleDateString('lv-LV') + ' - ' + new Date(invoiceEnds).toLocaleDateString('lv-LV') : '';

	return (
		<div>
			<div className={classes.paymentStatisticsContainer}>
				<p className={classes.statisticsNumber}><span className={classes.statisticsTitle}>
					Pieprasījumu skaits {invoiceEnds ? 'maksas periodā (' + periodDates + ')' : 'pēdējo 90 dienu laikā'}:
				</span> {!invoiceEnds && !trialPeriod ? '0' : recentStatistics}</p>

				<p className={classes.statisticsNumber}><span className={classes.statisticsTitle}>
					Apmaksājamā summa:
				</span> {invoiceEnds ? (recentStatistics <= 200 ? '30 EUR' : (30 + (recentStatistics - 200) * 0.05).toFixed(2) + ' EUR') : '0 EUR'}</p>
			</div>

			<TableContainer className={classes.tableContainer}>
				<Table className={classes.table} aria-label="Statistics table">
					<TableHead>
						<TableRow>
							<TableCell className={classes.tableHead}>Datums</TableCell>
							<TableCell className={classes.tableHead}>Vaicājumu skaits</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 ? statistics.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : statistics).map((row) => (
							<TableRow key={row.date}>
								<TableCell className={classes.tableCell} component="th" scope="row">
									{new Date(row.date).toLocaleDateString('lv-LV')}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{row.cnt}
								</TableCell>
							</TableRow>
						))}
					</TableBody>

					<TableFooter>
						<TableRow>
							<TablePagination
								className={classes.tablePagination}
								labelRowsPerPage={'Rindu skaits:'}
								rowsPerPageOptions={[5, 10, 25, { label: 'Viss', value: statistics.length }]}
								labelDisplayedRows={({ from, to, count }) => `${from}–${to} no ${count}`}
								colSpan={3}
								count={statistics.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	);
}

export default Statistics;
