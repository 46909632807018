import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { createInvoice } from '../createInvoice/slice';

const defaultState = {
	getting: false,
	getError: '',
	getStatus: null,
	statistics: [],
	recentStatistics: null
};

export const getStatisticsSlice = createSlice({
	name: 'statistics',
	initialState: defaultState,
	reducers: {
		getStatisticsSuccess: (state, action) => {
			state.getStatus = 'idle';
			state.getting = false;
		},
		getStatisticsFailure: (state, action) => {
			state.getError = action.payload;
			state.getStatus = 'idle';
			state.getting = false;
		},
		getStatisticsAttemptStarted: (state, action) => {
			state.getError = '';
			state.getStatus = 'started';
			state.getting = true;
		},
		setStatistics: (state, action) => {
			const { statistics, recentStatistics } = action.payload;
			state.statistics = statistics;
			state.recentStatistics = recentStatistics;
		},
		resetStatistics: (state, action) => {
			state.statistics = [];
			state.recentStatistics = 0;
		},
	},
});

const { actions } = getStatisticsSlice;

export const getStatistics = () => (dispatch, getState) => {
	dispatch(actions.getStatisticsAttemptStarted());

	api.data.statistics()
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, data } = resp;

			if (status === 'error') {
				dispatch(actions.getStatisticsFailure('Ievadīti nepareizi dati'));
				return;
			}

			if (status === 'blocked') {
				dispatch(actions.getStatisticsFailure('Lietotāja konts ir bloķēts! Lai atbloķētu kontu, rakstiet uz support@kartes.lv!'));
				return;
			}

			dispatch(actions.getStatisticsSuccess());
			dispatch(actions.setStatistics({ statistics: data.full, recentStatistics: data.recent.count }));

			if (data.recent.count > 20 && !getState().createInvoice.creating && !getState().createInvoice.invoiceEnds) {
				dispatch(createInvoice());
			}

		})
		.catch(() => {
			dispatch(actions.getStatisticsFailure('Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju'));
		});
};

export const resetStatistics = () => (dispatch, getState) => {
	dispatch(actions.resetStatistics());
};

export const getStatisticsSelectors = {
	isGetting: state => state.statistics.getting,
	error: state => state.statistics.getError,
	getStatus: state => state.statistics.getStatus,
	statistics: state => state.statistics.statistics,
	recentStatistics: state => state.statistics.recentStatistics,
};

export default getStatisticsSlice.reducer;
