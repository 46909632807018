import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Search from '../search';
import AccountInfo from '../accountInfo';
import Pricing from '../pricing';
import Contacts from '../contacts';
import About from '../about';
import ResetPassword from '../resetPassword';
import VerifyEmail from '../verifyEmail';
import NotFound from '../notFound';
import { Burtnieks } from '../icons'
import { MainButton } from '../buttons';

import { logout } from '../../features/auth/slice';
import { clearRequestData } from '../../features/data/slice';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	header: {
		alignItems: 'flex-end',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '45px',

		'@media (max-width: 850px)': {
			alignItems: 'stretch',
			flexDirection: 'column',
			gap: '25px',
		},
	},
	logoContainer: {
		alignItems: 'flex-end',
		display: 'flex',
		flexDirection: 'row',
		gap: '15px',
		width: 'fit-content',

		'& h3': {
			color: '#000000',
			userSelect: 'none',
			textTransform: 'uppercase',
		},
	},
	logo: {
		height: 'auto',
		width: '36px',
	},
	navBar: {
		alignItems: 'center',
		display: 'flex',
		gap: '42px',
		justifyContent: 'flex-end',
		position: 'relative',
		zIndex: '1',

		'@media (max-width: 600px)': {
			alignItems: 'flex-end',
			flexDirection: 'column',
			gap: '8px',
		},
	},
	navButton: {
		color: '#000000',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '16px',
		fontWeight: '500',
		padding: '0',
		textDecoration: 'none',
		userSelect: 'none',

		'&:hover': {
			textDecoration: 'underline',
			textDecorationColor: '#4DCF10',
			textUnderlineOffset: '2px',
			textDecorationThickness: '2px',
		},
	},
	activeNavButton: {
		color: '#000000',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '16px',
		fontWeight: '500',
		padding: '0',
		textDecoration: 'underline',
		textDecorationColor: '#4DCF10',
		textUnderlineOffset: '2px',
		textDecorationThickness: '2px',
		userSelect: 'none',
	},
	clear: {
		color: 'inherit',
		textDecoration: 'inherit',
	}
}));

const ContentSwitcher = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [activeSection, setActiveSection] = useState(window.location.pathname);

	const handleClick = (section) => {
		dispatch(clearRequestData());
		setActiveSection(section);
	}

	const handleLogout = () => {
		dispatch(logout());
	}

	const menuSections = [
		'/cena',
		'/kontakti',
		'/par',
		'/manskonts',
		'/reset_password',
		'/verify_email/:id/:token'
	];

	const getNavBar = () => {
		if (window.location.pathname === '/reset_password' || window.location.pathname.includes('/verify_email')) {
			return (
				<div className={classes.header}>
					<div className={classes.logoContainer}>
						<Burtnieks className={classes.logo} />
						<h3>Burtnieks</h3>
					</div>
				</div>
			);
		}

		return (
			<div className={classes.header}>
				<div className={classes.logoContainer}>
					<Burtnieks className={classes.logo} />
					<h3>Burtnieks</h3>
				</div>

				<div className={classes.navBar}>
					<Link to="/" onClick={() => handleClick('/')} className={!menuSections.includes(activeSection) ? classes.activeNavButton : classes.navButton}>Meklētājs</Link>
					<Link to={menuSections[0]} onClick={() => handleClick(menuSections[0])} className={activeSection === menuSections[0] ? classes.activeNavButton : classes.navButton}>Cena</Link>
					<Link to={menuSections[1]} onClick={() => handleClick(menuSections[1])} className={activeSection === menuSections[1] ? classes.activeNavButton : classes.navButton}>Kontakti</Link>
					<Link to={menuSections[2]} onClick={() => handleClick(menuSections[2])} className={activeSection === menuSections[2] ? classes.activeNavButton : classes.navButton}>Par</Link>
					<Link to={menuSections[3]} onClick={() => handleClick(menuSections[3])} className={activeSection === menuSections[3] ? classes.activeNavButton : classes.navButton}>Mans konts</Link>
					<MainButton onClick={handleLogout}><Link to="/" className={classes.clear}>Iziet</Link></MainButton>
				</div>
			</div>
		);
	}

	return (
		<BrowserRouter>
			{getNavBar()}

			<Routes>
				<Route exact path="/" element={<Search />}></Route>
				<Route path={menuSections[0]} element={<Pricing />}></Route>
				<Route path={menuSections[1]} element={<Contacts />}></Route>
				<Route path={menuSections[2]} element={<About />}></Route>
				<Route path={menuSections[3]} element={<AccountInfo />}></Route>
				<Route path={menuSections[4]} element={<ResetPassword />}></Route>
				<Route path={menuSections[5]} element={<VerifyEmail />}></Route>
				<Route path="*" element={<NotFound />}></Route>
			</Routes>
		</BrowserRouter>
	);
}

export default ContentSwitcher;
