/* eslint-disable object-property-newline */

export const countryIsoCodes = {
	ABW: 'Aruba', AFG: 'Afghanistāna', AGO: 'Angola', AIA: 'Angilija', ALA: 'Ālandu Salas',
	ALB: 'Albānija', AND: 'Andora', ARE: 'Apvienotie Arābu Emirāti', ARG: 'Argentīna', ARM: 'Armēnija',
	ASM: 'Amerikāņu Samoa', ATA: 'Antarktica', ATF: 'Francijas Dienvidjūru un Antarktikas Zemes',
	ATG: 'Antigva un Barbuda', AUS: 'Austrālija', AUT: 'Austrija', AZE: 'Azerbaidžāna', BDI: 'Burundija',
	BEL: 'Beļģija', BEN: 'Benina', BES: 'Bonaire, Sintēstatiusa un Saba', BFA: 'Burkinafaso',
	BGD: 'Bangladeša', BGR: 'Bulgārija', BHR: 'Bahreina', BHS: 'Bahamas', BIH: 'Bosnija un Hercegovina',
	BLM: 'Senbartelmī', BLR: 'Baltkrievija', BLZ: 'Beliza', BMU: 'Bermudu salas', BOL: 'Bolīvija',
	BRA: 'Brazīlija', BRB: 'Barbadosa', BRN: 'Bruneja', BTN: 'Butāna', BVT: 'Buvē sala', BWA: 'Botsvāna',
	CAF: 'Centrālāfrikas Republika', CAN: 'Kanāda', CCK: 'Kokosu (Kīlinga) Salas', CHE: 'Šveice',
	CHL: 'Čīle', CHN: 'Ķīna', CIV: 'Kotdivuāra', CMR: 'Kamerūna', COD: 'Kongo Demokrātiskā Republika',
	COG: 'Kongo Republika', COK: 'Kuka Salas', COL: 'Kolumbija', COM: 'Komoras', CPV: 'Kaboverde',
	CRI: 'Kostarika', CUB: 'Kuba', CUW: 'Kirasao', CXR: 'Ziemsvētku Sala', CYM: 'Kaimanu Salas',
	CYP: 'Kipra', CZE: 'Čehija', DEU: 'Vācija', DJI: 'Džibutija', DMA: 'Dominika', DNK: 'Dānija',
	DOM: 'Dominikāna', DZA: 'Alžīrija', ECU: 'Ekvadora', EGY: 'Ēģipte', ERI: 'Eritreja',
	ESH: 'Rietumsahāra', ESP: 'Spānija', EST: 'Igaunija', ETH: 'Etiopija', FIN: 'Somija', FJI: 'Fidži',
	FLK: 'Folklenda Salas', FRA: 'Francija', FRO: 'Fēru Salas', FSM: 'Mikronēzija', GAB: 'Gabona',
	GBR: 'Apvienotā Karaliste', GEO: 'Gruzija', GGY: 'Gērnsija', GHA: 'Gana', GIB: 'Gibraltārs',
	GIN: 'Gvineja', GLP: 'Gvadelupa', GMB: 'Gambija', GNB: 'Gvineja-Bisava', GNQ: 'Ekvatoriālā Gvineja',
	GRC: 'Grieķija', GRD: 'Grenāda', GRL: 'Grenlande', GTM: 'Gvatemala', GUF: 'Franču Gviāna',
	GUM: 'Guama', GUY: 'Gajāna', HKG: 'Hongkonga', HMD: 'Hērda Sala un Makdonalda Salas', HND: 'Hondurasa',
	HRV: 'Horvātija', HTI: 'Haiti', HUN: 'Ungārija', IDN: 'Indonēzija', IMN: 'Menas Sala', IND: 'Indija',
	IOT: 'Britu Indijas Okeāna Teritorija', IRL: 'Īrija', IRN: 'Irāna', IRQ: 'Irāka', ISL: 'Islande',
	ISR: 'Izraēla', ITA: 'Itālija', JAM: 'Jamaika', JEY: 'Džērsija', JOR: 'Jordānija', JPN: 'Japāna',
	KAZ: 'Kazahstāna', KEN: 'Kenija', KGZ: 'Kirgizstāna', KHM: 'Kambodža', KIR: 'Kiribati',
	KNA: 'Sentkitsa un Nevisa', KOR: 'Dienvidkoreja', KWT: 'Kuveita', LAO: 'Laosa', LBN: 'Libāna',
	LBR: 'Libērija', LBY: 'Lībija', LCA: 'Sentlūsija', LIE: 'Lihtenšteina', LKA: 'Šrilanka',
	LSO: 'Lesoto', LTU: 'Lietuva', LUX: 'Luksemburga', LVA: 'Latvija', MAC: 'Makao', MAF: 'Senmartēna',
	MAR: 'Maroka', MCO: 'Monako', MDA: 'Moldova', MDG: 'Madagaskara', MDV: 'Maldīvija', MEX: 'Meksika',
	MHL: 'Māršala Salas', MKD: 'Ziemeļmaķedonija', MLI: 'Mali', MLT: 'Malta', MMR: 'Mjanma',
	MNE: 'Melnkalne', MNG: 'Mongolija', MNP: 'Ziemeļu Marianas Salas', MOZ: 'Mozambika', MRT: 'Mauritānija',
	MSR: 'Montserrata', MTQ: 'Martinika', MUS: 'Maurīcija', MWI: 'Malāvija', MYS: 'Malaizija',
	MYT: 'Majota', NAM: 'Namībija', NCL: 'Jaunkaledonija', NER: 'Nigēra', NFK: 'Norfolkas Sala',
	NGA: 'Nigērija', NIC: 'Nikaragva', NIU: 'Niue', NLD: 'Nīderlande', NOR: 'Norvēģija', NPL: 'Nepāla',
	NRU: 'Nauru', NZL: 'Jaunzēlande', OMN: 'Omāna', PAK: 'Pakistāna', PAN: 'Panama', PCN: 'Pitkērna',
	PER: 'Peru', PHL: 'Filipīnas', PLW: 'Palau', PNG: 'Papua-Jaungvineja', POL: 'Polija', PRI: 'Puertoriko',
	PRK: 'Ziemeļkoreja', PRT: 'Portugāle', PRY: 'Paragvaja', PSE: 'Palestīna', PYF: 'Franču Polinēzija',
	QAT: 'Katara', REU: 'Reinjona', ROU: 'Rumānija', RUS: 'Krievija', RWA: 'Ruanda', SAU: 'Saūda Arābija',
	SDN: 'Sudāna', SEN: 'Senegāla', SGP: 'Singapūra', SGS: 'Dienviddžordžija un Dienvidsendviču Salas',
	SHN: 'Svētās Helēnas sala, Debesbraukšanas sala un Tristana da Kuņas salas', SJM: 'Svalbāra un Jana Majena sala',
	SLB: 'Zālamana Salas', SLE: 'Sjerraleone', SLV: 'Salvadora', SMR: 'Sanmarīno', SOM: 'Somālija',
	SPM: 'Senpjēra un Mikelona', SRB: 'Serbija', SSD: 'Dienvidsudāna', STP: 'Santome un Prinsipi',
	SUR: 'Surinama', SVK: 'Slovākija', SVN: 'Slovēnija', SWE: 'Zviedrija', SWZ: 'Svatini',
	SXM: 'Sintmārtena', SYC: 'Seišelas', SYR: 'Sīrija', TCA: 'Tērksas un Kaikosas', TCD: 'Čada',
	TGO: 'Togo', THA: 'Taizeme', TJK: 'Tadžikistāna', TKL: 'Tokelau', TKM: 'Turkmenistāna',
	TLS: 'Austrumtimora', TON: 'Tonga', TTO: 'Trinidāda un Tobāgo', TUN: 'Tunisija', TUR: 'Turcija',
	TUV: 'Tuvalu', TWN: 'Taivāna', TZA: 'Tanzānija', UGA: 'Uganda', UKR: 'Ukraina',
	UMI: 'ASV Mazās aizjūras salas', URY: 'Urugvaja', USA: 'Amerikas Savienotās Valstis', UZB: 'Uzbekistāna',
	VAT: 'Vatikāns', VCT: 'Sentvinsenta un Grenadīnas', VEN: 'Venecuēla', VGB: 'Britu Virdžīnu salas',
	VIR: 'ASV Virdžīnu salas', VNM: 'Vjetnama', VUT: 'Vanuatu', WLF: 'Volisa un Futuna', WSM: 'Samoa',
	YEM: 'Jemena', ZAF: 'Dienvidāfrika', ZMB: 'Zambija', ZWE: 'Zimbabve'
};

export const languageIsoCodes = {
	aar: 'Afāru valoda', abk: 'Abhāzu valoda', ace: 'Ačihu valoda', ach: 'Ačolu valoda', ada: 'Adangmu valoda',
	ady: 'Adigu valoda', afa: 'Afroaziātu valodas', afh: 'Afrihili', afr: 'Afrikandu valoda', ain: 'Ainu valoda',
	aka: 'Akanu valoda', akk: 'Akadiešu valoda', ale: 'Aleutu valoda', alg: 'Algonkinu valodas', alt: 'Dienvidaltajiešu valoda',
	amh: 'Amharu valoda', ang: 'Senangļu valoda', anp: 'Angika', apa: 'Apaču valodas', ara: 'Arābu valoda',
	arc: 'Aramiešu valoda', arg: 'Aragoniešu valoda', arn: 'Araukāņu valoda', arp: 'Arapahu valoda', art: 'Mākslīgas valodas',
	arw: 'Aravaku valoda', asm: 'Asamiešu valoda', ast: 'Astūriešu valoda', ath: 'Atapasku valodas', aus: 'Austrāliešu valodas',
	ava: 'Avāru valoda', ave: 'Avesta', awa: 'Avadhu valoda', aym: 'Aimaru valoda', aze: 'Azerbaidžāņu valoda',
	bad: 'Bandu valoda', bai: 'Bamileku valodas', bak: 'Baškīru valoda', bal: 'Beludžu valoda', bam: 'Bambaru valoda',
	ban: 'Baliešu valoda', bas: 'Basu valoda', bat: 'Baltu valodas', bej: 'Bedžu valoda', bel: 'Baltkrievu valoda',
	bem: 'Bembu valoda', ben: 'Bengāļu valoda', ber: 'Berberu valodas', bho: 'Bhodžpūru valoda', bih: 'Biharu valodas',
	bik: 'Bikolu valoda', bin: 'Binu valoda', bis: 'Bišlamā', bla: 'Siksiku valoda', bnt: 'Bantu valodas',
	bod: 'Tibetiešu valoda', tib: 'Tibetiešu valoda', bos: 'Bosniešu valoda', bra: 'Bradžiešu valoda',
	bre: 'Bretoņu valoda', btk: 'Bataku valodas', bua: 'Burjatu valoda', bug: 'Bugu valoda', bul: 'Bulgāru valoda',
	byn: 'Bilinu valoda', cad: 'Kadu valoda', cai: 'Centrālamerikas valodas', car: 'Karību valoda', cat: 'Katalāņu valoda',
	cau: 'Kaukāziešu valodas', ceb: 'Sebuāņu valoda', cel: 'Ķeltu valodas', ces: 'Čehu valoda', cze: 'Čehu valoda',
	cha: 'Čamorru valoda', chb: 'Čibču valoda', che: 'Čečenu valoda', chg: 'Džagatajs', chk: 'Čūku valoda',
	chm: 'Mariešu valoda', chn: 'Činuku žargons', cho: 'Čoktavu valoda', chp: 'Čipevaianu valoda', chr: 'Čiroku valoda',
	chu: 'Senslāvu valoda', chv: 'Čuvašu valoda', chy: 'Šejenu valoda', cmc: 'Čamiešu valodas', cnr: 'Melnkalniešu valoda',
	cop: 'Koptu valoda', cor: 'Korniešu valoda', cos: 'Korsikāņu valoda', cpe: 'Angliskās kreoliskās valodas un pidžinvalodas',
	cpf: 'Franciskās kreoliskās valodas un pidžinvalodas', cpp: 'Portugāliskās kreoliskās valodas un pidžinvalodas',
	cre: 'Krī valoda', crh: 'Krimas tatāru valoda', crp: 'Kreoliskās valodas un pidžinvalodas', csb: 'Kašubu valoda',
	cus: 'Kušītu valodas', cym: 'Velsiešu valoda', wel: 'Velsiešu valoda', dak: 'Dakotu valoda', dan: 'Dāņu valoda',
	dar: 'Dargu valoda', day: 'Dajaku valodas', del: 'Delavēru valoda', den: 'Sleivu (atapasku) valoda', deu: 'Vācu valoda',
	ger: 'Vācu valoda', dgr: 'Dogribu valoda', din: 'Dinku valoda', div: 'Maldīviešu valoda', doi: 'Dogru valoda',
	dra: 'Dravīdu valodas', dsb: 'Lejassorbu valoda', dua: 'Dualu valoda', dum: 'Vidusholandiešu valoda (ap 1050-1350)',
	dyu: 'Diūlu valoda', dzo: 'Dzongke', efi: 'Efiku valoda', egy: 'Ēģiptiešu valoda', eka: 'Ekadžuku valoda',
	ell: 'Grieķu valoda', gre: 'Grieķu valoda', elx: 'Elamiešu valoda', eng: 'Angļu valoda', enm: 'Vidusangļu valoda (1100-1500)',
	epo: 'Esperanto', est: 'Igauņu valoda', eus: 'Basku valoda', baq: 'Basku valoda', ewe: 'Evu valoda', ewo: 'Evondu valoda',
	fan: 'Fangu valoda', fao: 'Fēru valoda', fas: 'Persiešu valoda', per: 'Persiešu valoda', fat: 'Fantu valoda',
	fij: 'Fidžiešu valoda', fil: 'Filipīniešu valoda', fin: 'Somu valoda', fiu: 'Somugru valodas', fon: 'Fonu valoda',
	fra: 'Franču valoda', fre: 'Franču valoda', frm: 'Vidusfranču valoda (ap 1400-1600)', fro: 'Senfranču valoda (842-ap 1400)',
	frr: 'Ziemeļfrīzu valoda', frs: 'Austrumfrīzu valoda', fry: 'Rietumfrīzu valoda', ful: 'Fulu valoda', fur: 'Friūlu valoda',
	gaa: 'Ga valoda', gay: 'Gajo valoda', gba: 'Gbaju valoda', gem: 'Ģermāņu valodas', gez: 'Gēzu valoda',
	gil: 'Kiribatiešu valoda', gla: 'Gēlu valoda', gle: 'Īru valoda', glg: 'Galisiešu valoda', glv: 'Meniešu valoda',
	gmh: 'Vidusaugšvācu valoda (ap 1050-1500)', goh: 'Senaugšvācu valoda (ap 750-1050)', gon: 'Gondu valodas',
	gor: 'Gorontalu valoda', got: 'Gotu valoda', grb: 'Grebo valoda', grc: 'Sengrieķu valoda (līdz 1453)', grn: 'Gvaranu valoda',
	gsw: 'Šveices vācu valoda', guj: 'Gudžaratu valoda', gwi: 'Kučinu valoda', hai: 'Haidu valoda', hat: 'Haitiešu valoda',
	hau: 'Hausu valoda', haw: 'Havajiešu valoda', heb: 'Ivrits', her: 'Hereru valoda', hil: 'Hiligainonu valoda',
	him: 'Himačali, pahari', hin: 'Hindi', hit: 'Hetu valoda', hmn: 'Hmongu valoda', hmo: 'Hirimotu valoda',
	hrv: 'Horvātu valoda', hsb: 'Augšsorbu valoda', hun: 'Ungāru valoda', hup: 'Hupu valoda', hye: 'Armēņu valoda',
	arm: 'Armēņu valoda', iba: 'Ibanu valoda', ibo: 'Igbo valoda', ido: 'Ido', iii: 'Sičuaņas ji valoda',
	ijo: 'Idžbu valodas', iku: 'Inuītu valoda', ile: 'Interlingve', ilo: 'Iloku valoda', ina: 'Interlingva (starptautiskā palīgvaloda)',
	inc: 'Indoāriešu valodas', ind: 'Indonēziešu valoda', ine: 'Indoeiropiešu valodas', inh: 'Ingušu valoda',
	ipk: 'Inupiaku valoda', ira: 'Irāņu valodas', iro: 'Irokēzu valodas', isl: 'Islandiešu valoda', ice: 'Islandiešu valoda',
	ita: 'Itāļu valoda', jav: 'Javiešu valoda', jbo: 'Ložbans', jpn: 'Japāņu valoda', jpr: 'Jūdpersiešu valoda',
	jrb: 'Jūdarābu valoda', kaa: 'Karakalpaku valoda', kab: 'Kabilu valoda', kac: 'Kačinu valoda', kal: 'Grenlandiešu valoda',
	kam: 'Kambu valoda', kan: 'Kannadu valoda', kar: 'Karenu valoda', kas: 'Kašmiriešu valoda', kat: 'Gruzīnu valoda',
	geo: 'Gruzīnu valoda', kau: 'Kanuru valoda', kaw: 'Kāvi', kaz: 'Kazahu valoda', kbd: 'Kabardiešu valoda',
	kha: 'Khasu valoda', khi: 'Hojsanu valodas', khm: 'Khmeru valoda', kho: 'Hotaniešu valoda', kik: 'Kikuju valoda',
	kin: 'Kiņaruanda', kir: 'Kirgīzu valoda', kmb: 'Kimbundu valoda', kok: 'Konkanu valoda', kom: 'Komiešu valoda',
	kon: 'Kongu valoda', kor: 'Korejiešu valoda', kos: 'Kosrājiešu valoda', kpe: 'Kpellu valoda', krc: 'Karačaju un balkāru valoda',
	krl: 'Karēļu valoda', kro: 'Krū valoda', kru: 'Kuruhu valoda', kua: 'Kvaņamu valoda', kum: 'Kumiku valoda',
	kur: 'Kurdu valoda', kut: 'Kutenaju valoda', lad: 'Ladino', lah: 'Landu valoda', lam: 'Lambu valoda',
	lao: 'Laosiešu valoda', lat: 'Latīņu valoda', lav: 'Latviešu valoda', lez: 'Lezgīnu valoda', lim: 'Limburgiešu valoda',
	lin: 'Lingala', lit: 'Lietuviešu valoda', lol: 'Mongu valoda', loz: 'Lozu valoda', ltz: 'Luksemburgiešu valoda',
	lua: 'Lubalulva', lub: 'Lubakatanga', lug: 'Gandu valoda', lui: 'Luisenu valoda', lun: 'Lundu valoda',
	luo: 'Luo valoda (Kenijā un Tanzānijā)', lus: 'Lušeju valoda', mad: 'Maduriešu valoda', mag: 'Magahiešu valoda',
	mah: 'Māršaliešu valoda', mai: 'Maithili', mak: 'Makasaru valoda', mal: 'Malajalu valoda', man: 'Mandingu valoda',
	map: 'Austronēziešu valodas', mar: 'Marathu valoda', mas: 'Masaju valoda', mdf: 'Mokšu valoda', mdr: 'Mandaru valoda',
	men: 'Mendu valoda', mga: 'Vidusīru valoda (900-1200)', mic: 'mikmaku valoda', min: 'Minangkabavu valoda',
	mis: 'Dažādas valodas', mkd: 'Maķedoniešu valoda', mac: 'Maķedoniešu valoda', mkh: 'Monkhmeru valodas',
	mlg: 'Malagasu valoda', mlt: 'Maltiešu valoda', mnc: 'Mandžūru valoda', mni: 'Manipūru valoda', mno: 'Manobu valodas',
	moh: 'Mohauku valoda', mon: 'Mongoļu valoda', mos: 'Mosu valoda', mri: 'Maoru valoda', mao: 'Maoru valoda',
	msa: 'Malajiešu valoda', may: 'Malajiešu valoda', mul: 'Vairākas valodas', mun: 'Mundu valodas', mus: 'Krīku valoda',
	mwl: 'Mirandiešu valoda', mwr: 'Marvaru valoda', mya: 'Birmiešu valoda', bur: 'Birmiešu valoda', myn: 'Maiju valodas',
	myv: 'Erzju valoda', nah: 'Navatlu valoda', nai: 'Ziemeļamerikas indiāņu valodas', nap: 'Neapoliešu valoda',
	nau: 'Nauruiešu valoda', nav: 'Navahu valoda', nbl: 'Dienvidndebelu valoda', nde: 'Ziemeļndebelu valoda',
	ndo: 'Ndongu valoda', nds: 'Lejasvācu valoda', nep: 'Nepāliešu valoda', new: 'Nevaru valoda (nepālbhasa)',
	nia: 'Njasu valoda', nic: 'Nigēriešu-kordofāņu valodas', niu: 'Niuāņu valoda', nld: 'Holandiešu, flāmu valoda',
	dut: 'Holandiešu, flāmu valoda', nno: 'Jaunnorvēģu valoda', nob: 'Norvēģu bukmols', nog: 'Norvēģu bukmols',
	non: 'Sennorvēģu valoda', nor: 'Norvēģu valoda', nqo: 'N\'Ko valoda', nso: 'Ziemeļsotu valoda', nub: 'Nūbiešu valodas',
	nwc: 'Klasiskā nevaru valoda', nya: 'Čičeva', nym: 'Ņamvezu valoda', nyn: 'Ņankolu valoda', nyo: 'Ņoru valoda',
	nzi: 'Nzemu valoda', oci: 'Oksitāņu valoda', oji: 'Odžibvu valoda', ori: 'Oriju valoda', orm: 'Oromu valoda',
	osa: 'Važāžu valoda', oss: 'Osetīnu valoda', ota: 'Turku (osmaņu) valoda (1500-1928)', oto: 'Otomu valodas',
	paa: 'Papuasu valodas', pag: 'Pangasinanu valoda', pal: 'Viduspersiešu valoda, pehlevi', pam: 'Pampanganu valoda',
	pan: 'Pandžabu valoda', pap: 'Papjamento', pau: 'Palaviešu valoda', peo: 'Senpersiešu valoda (ap 600-400 p.m.ē.)',
	phi: 'Filipīniešu valodas', phn: 'Feniķiešu valoda', pli: 'Pāli valoda', pol: 'Poļu valoda', pon: 'Ponapiešu valoda',
	por: 'Portugāļu valoda', pra: 'Prākrita valodas', pro: 'Senprovansiešu valoda (līdz 1500)', pus: 'Puštu',
	que: 'Kečvu valoda', raj: 'Radžastāņu valoda', rap: 'Rapanuju valoda', rar: 'Rarotongiešu valoda', roa: 'Romāņu valodas',
	roh: 'Retoromāņu valoda', rom: 'Romu valoda', ron: 'Rumāņu valoda', rum: 'Rumāņu valoda', run: 'Rundu valoda',
	rup: 'Aromūnu valoda', rus: 'Krievu valoda', sad: 'Sandavu valoda', sag: 'Sangu valoda', sah: 'Jakutu valoda',
	sai: 'Dienvidamerikas indiāņu valodas', sal: 'Sališu valodas', sam: 'Samārijas aramiešu valoda', san: 'Sanskrits',
	sas: 'Sasaku valoda', sat: 'Santalu valoda', scn: 'Sicīliešu valoda', sco: 'Skotu valoda', sel: 'Selkupu valoda',
	sem: 'Semītu valodas', sga: 'Senīru valoda (līdz 900)', sgn: 'Zīmju valodas', shn: 'Šanu valoda', sid: 'Sidamu valoda',
	sin: 'Singāļu valoda', sio: 'Siū valodas', sit: 'Sinotibetiešu valodas', sla: 'Slāvu valodas', slk: 'Slovāku valoda',
	slo: 'Slovāku valoda', slv: 'Slovēņu valoda', sma: 'Dienvidsāmu valoda', sme: 'Ziemeļsāmu valoda', smi: 'Sāmu valodas',
	smj: 'Luleo sāmu valoda', smn: 'Inari sāmu valoda', smo: 'Samoāņu valoda', sms: 'Skoltsāmu valoda', sna: 'Šonu valoda',
	snd: 'Sindhu valoda', snk: 'Soninku valoda', sog: 'Sogdiešu valoda', som: 'Somāļu valoda', son: 'Songaju valodas',
	sot: 'Dienvidsotu valoda', spa: 'Spāņu, kastīliešu valoda', sqi: 'Albāņu valoda', alb: 'Albāņu valoda',
	srd: 'Sardīniešu valoda', srn: 'Sranantogo', srp: 'Serbu valoda', srr: 'Serēru valoda', ssa: 'Nīlas-Sahāras valodas',
	ssw: 'Svatu valoda', suk: 'Sukumu valoda', sun: 'Zundu valoda', sus: 'Susu valoda', sux: 'Šumēru valoda',
	swa: 'Svahili', swe: 'Zviedru valoda', syc: 'Sīriešu valoda', syr: 'Sīriešu valoda', tah: 'Taitiešu valoda',
	tai: 'Taju valodas', tam: 'Tamilu valoda', tat: 'Tatāru valoda', tel: 'Telugu valoda', tem: 'Temnu valoda',
	ter: 'Tereno valoda', tet: 'Tetumu valoda', tgk: 'Tadžiku valoda', tgl: 'Tagalu valoda', tha: 'Taju valoda',
	tig: 'Tigru valoda', tir: 'Tigrinja', tiv: 'Tivu valoda', tkl: 'Tokelaviešu valoda', tlh: 'Klingoņu valoda',
	tli: 'Tlinkitu valoda', tmh: 'Tuaregu valoda', tog: 'Tongu valoda (Njasā)', ton: 'Tongiešu valoda (Tongas salās)',
	tpi: 'Tokpisins', tsi: 'Cimšiāņu valoda', tsn: 'Cvanu valoda', tso: 'Congu valoda', tuk: 'Turkmēņu valoda',
	tum: 'Tumbuku valoda', tup: 'Tupu valodas', tur: 'Turku valoda', tut: 'Altajiešu valodas', tvl: 'Tuvaliešu valoda',
	twi: 'Tvī valoda', tyv: 'Tuviešu valoda', udm: 'Udmurtu valoda', uga: 'Ugaritiešu valoda', uig: 'Uiguru valoda',
	ukr: 'Ukraiņu valoda', umb: 'Umbundu valoda', und: 'Neatpazīta valoda', urd: 'Urdu', uzb: 'Uzbeku valoda',
	vai: 'Vaju valoda', ven: 'Vendu valoda', vie: 'Vjetnamiešu valoda', vol: 'Volapiks', vot: 'Votu valoda',
	wak: 'Vakašu valodas', wal: 'Valamu valoda', war: 'Varaju valoda', was: 'Vašo valoda', wen: 'Sorbu valoda',
	wln: 'Valoņu valoda', wol: 'Volofu valoda', xal: 'Kalmiku valoda', xho: 'Khosu valoda', yao: 'Jao valoda',
	yap: 'Japiešu valoda', yid: 'Jidišs', yor: 'Jorubu valoda', ypk: 'Jupiku valodas', zap: 'Sapoteku valoda',
	zen: 'Zenagu valoda', zha: 'Džuanu, čuanu valoda', zho: 'Ķīniešu valoda', chi: 'Ķīniešu valoda', znd: 'Zandē valodas',
	zul: 'Zulu valoda', zun: 'Zunju valoda', zxx: 'Lingvistiska satura nav', zza: 'Zazu valoda'
};
