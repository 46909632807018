import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { login } from '../auth/slice';

const defaultState = {
	registering: false,
	registerError: '',
	registerStatus: null,
};

export const registerSlice = createSlice({
	name: 'register',
	initialState: defaultState,
	reducers: {
		registerSuccess: (state, action) => {
			state.registerStatus = 'success';
			state.registering = false;
		},
		registerFailure: (state, action) => {
			state.registerError = action.payload;
			state.registerStatus = 'idle';
			state.registering = false;
		},
		registerAttemptStarted: (state, action) => {
			state.registerError = '';
			state.registerStatus = 'started';
			state.registering = true;
		},
		resetStatus: (state, action) => {
			state.registerError = '';
			state.registerStatus = 'idle';
		},
	},
});

const { actions } = registerSlice;

export const register = ({ name, email, password }) => (dispatch, getState) => {
	dispatch(actions.registerAttemptStarted());

	api.user.register({ name, email, password })
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, user } = resp;

			if (status === 'error') {
				dispatch(actions.registerFailure('Lietotājs ar šādu e-pasta adresi jau pastāv. Lūdzu, ievadiet citu e-pasta adresi vai autentificējieties sistēmā!'));
				return;
			}

			dispatch(actions.registerSuccess());
			dispatch(login({ email: email, password: password }));
		})
		.catch(() => {
			dispatch(actions.registerFailure('Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju'));
		});
};

export const resetRegisterStatus = () => (dispatch, getState) => {
	dispatch(actions.resetStatus());
};

export const registerSelectors = {
	isRegistering: state => state.register.registering,
	error: state => state.register.registerError,
	registerStatus: state => state.register.registerStatus,
};

export default registerSlice.reducer;
