import { get, post, put } from '../api';

const data = {
	statistics: async () => {
		return get('statistics');
	},
	request: async ({ lang, query }) => {
		return get('place/search', { lang, query });
	},
};

export default data;
