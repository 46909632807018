import React from 'react';
import { Link } from 'react-router-dom';

import { Burtnieks } from '../icons'

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	aboutContainer: {
		width: '500px',

		'@media (max-width: 850px)': {
			width: '100%',
		},
	},
	logoContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		gap: '19px',
		padding: '0 0 40px 40px',
		width: 'fit-content',

		'& h3': {
			color: '#000000',
			userSelect: 'none',
			textTransform: 'uppercase',
		},
	},
	logo: {
		height: 'auto',
		marginLeft: '-15px',
		width: '57px',
	},
	textBox: {
		backgroundColor: '#000000',
		padding: '40px',

		'& div': {
			marginBottom: '25px',
		},

		'& h4': {
			color: '#E0DFDC',
			marginBottom: '25px',
		},

		'& li': {
			color: '#E0DFDC',
			marginLeft: '20px',
		},

		'& p': {
			color: '#E0DFDC',
		},

		'& a': {
			color: '#4DCF10',
			cursor: 'pointer',
			textDecoration: 'none',

			'&:hover': {
				color: '#FFFFFF',
				textDecoration: 'underline',
				textDecorationColor: '#4DCF10',
				textUnderlineOffset: '2px',
				textDecorationThickness: '2px',
			},
		},
	}
}));

const LoginAbout = ({ sectionChange }) => {
	const classes = useStyles();

	return (
		<div className={classes.aboutContainer}>
			<div className={classes.logoContainer}>
				<Burtnieks className={classes.logo} />
				<h3>Burtnieks</h3>
			</div>

			<div className={classes.textBox}>
				<h4>Burtnieks – Latvijā pirmais vietvārdu atveidošanas risinājums!</h4>

				<div>
					<p>Vairāk nekā 100 000 vietvārdu no visas pasaules ar meklēšanu abos virzienos:</p>
					<ul>
						<li>latviskais nosaukums → oriģinālnosaukums;</li>
						<li>oriģinālnosaukums → latviskais nosaukums!</li>
					</ul>
				</div>

				<div>
					<p>Lasi vairāk sadaļā <Link to="/par" onClick={() => sectionChange('/par')}>Par</Link>!</p>
				</div>

				<p><strong>Izmēģini par brīvu!</strong></p>
			</div>
		</div>
	);
}

export default LoginAbout;
