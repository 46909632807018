import React from 'react';

import { makeStyles } from '@mui/styles';
import { List, ListItemButton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	listContainer: {
		maxHeight: '328px',
		width: '500px',
		padding: '0',
		overflowY: 'auto',

		'& .MuiListItemButton-root': {
			borderBottom: '1px solid #000000',
			color: '#000000',
			fontFamily: 'Raleway, Arial, sans-serif',
			fontSize: '16px',
			fontWeight: '500',
			padding: '10px 22px',

			'&:hover': {
				backgroundColor: '#FFFFFF',
			},
		},

		'& .Mui-selected': {
			backgroundColor: '#FFFFFF',
		},

		'@media (max-width: 850px)': {
			width: '100%',
		},
	},
}));

const MenuList = ({ listContents, onClick, selectedIndex, itemNameModifier = null }) => {
	const classes = useStyles();
	let key = 0;

	return (
		<List className={classes.listContainer}>
			{listContents.map(item => (
				<ListItemButton
					key={key++}
					onClick={() => onClick(item.properties.id)}
					selected={item.properties.id === selectedIndex}
					value={item.properties.id}
				>
					<span>{itemNameModifier ? itemNameModifier(item) : item.properties.name}</span>
				</ListItemButton>
			))}
		</List>
	);
}

export default MenuList;
