import React from 'react';

import { makeStyles } from '@mui/styles';
import { Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	messageContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '5px',
		left: '50%',
		position: 'absolute',
		transform: 'translate(-50%, -110%)',
		whiteSpace: 'nowrap',
		width: 'fit-content',
		zIndex: '1',

		'@media (max-width: 850px)': {
			left: '20px',
			top: '39px',
			transform: 'unset',
			whiteSpace: 'break-spaces',
			width: 'calc(100% - 40px)',
		},
	},
	successMessage: {
		backgroundColor: '#E9FFE9',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '600',
		padding: '4px 16px',

		'& .MuiAlert-icon': {
			opacity: '1',
		}
	},
	errorMessage: {
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '600',
		padding: '4px 16px',
	},
}));

export const TopMessageContainer = props => {
	const classes = useStyles();

	return (
		<div className={classes.messageContainer} {...props} />
	);
};

export const SuccessMessage = ({ message, onClose = null }) => {
	const classes = useStyles();

	return (
		<Alert className={classes.successMessage} severity="success" onClose={onClose}>{message}</Alert>
	);
};

export const ErrorMessage = ({ message, onClose = null }) => {
	const classes = useStyles();

	return (
		<Alert className={classes.errorMessage} severity="error" onClose={onClose}>{message}</Alert>
	);
};
