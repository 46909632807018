import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { setInvoice } from '../createInvoice/slice';

const defaultState = {
	renewing: false,
	renewError: '',
	renewStatus: null,
};

export const renewInvoiceSlice = createSlice({
	name: 'renewInvoice',
	initialState: defaultState,
	reducers: {
		renewInvoiceSuccess: (state, action) => {
			state.renewStatus = 'success';
			state.renewing = false;
		},
		renewInvoiceFailure: (state, action) => {
			state.renewError = action.payload;
			state.renewStatus = 'idle';
			state.renewing = false;
		},
		renewInvoiceAttemptStarted: (state, action) => {
			state.renewError = '';
			state.renewStatus = 'started';
			state.renewing = true;
		},
		resetStatus: (state, action) => {
			state.renewError = '';
			state.renewStatus = 'idle';
		},
	},
});

const { actions } = renewInvoiceSlice;

export const stopRenewingInvoice = () => (dispatch, getState) => {
	dispatch(actions.renewInvoiceAttemptStarted());

	api.invoice.stopRenewing()
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, data } = resp;

			if (status === 'error') {
				dispatch(actions.renewInvoiceFailure('Notika kļūda, mēģinot atteikties no abonēšanas!'));
				return;
			}

			if (status === 'blocked') {
				dispatch(actions.renewInvoiceFailure('Lietotāja konts ir bloķēts! Lai atbloķētu kontu, rakstiet uz support@kartes.lv!'));
				return;
			}

			if (status === 'success') {
				dispatch(actions.renewInvoiceSuccess());
				dispatch(setInvoice({ invoiceStarts: data.created_at, invoiceEnds: data.ends_at, invoiceRenewed: data.is_renewed, invoicePaid: data.is_paid }));
			}
		})
		.catch(() => {
			dispatch(actions.renewInvoiceFailure('Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju'));
		});
};

export const startRenewingInvoice = () => (dispatch, getState) => {
	dispatch(actions.renewInvoiceAttemptStarted());

	api.invoice.startRenewing()
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, data } = resp;

			if (status === 'error') {
				dispatch(actions.renewInvoiceFailure('Notika kļūda, mēģinot atsākt abonēt pakalpojumu!'));
				return;
			}

			if (status === 'blocked') {
				dispatch(actions.renewInvoiceFailure('Lietotāja konts ir bloķēts! Lai atbloķētu kontu, rakstiet uz support@kartes.lv!'));
				return;
			}

			if (status === 'success') {
				dispatch(actions.renewInvoiceSuccess());
				dispatch(setInvoice({ invoiceStarts: data.created_at, invoiceEnds: data.ends_at, invoiceRenewed: data.is_renewed, invoicePaid: data.is_paid }));
			}
		})
		.catch(() => {
			dispatch(actions.renewInvoiceFailure('Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju'));
		});
};

export const resetRenewInvoiceStatus = () => (dispatch, getState) => {
	dispatch(actions.resetStatus());
};

export const renewInvoiceSelectors = {
	isRenewing: state => state.renewInvoice.renewing,
	error: state => state.renewInvoice.renewError,
	renewStatus: state => state.renewInvoice.renewStatus,
};

export default renewInvoiceSlice.reducer;
