import auth from './methods/auth';
import data from './methods/data';
import invoice from './methods/invoice';
import suggest from './methods/suggest';
import user from './methods/user';

const api = {
	auth, data, invoice, suggest, user
}

export default api;
