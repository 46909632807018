import { get, post, put } from '../api';

const invoice = {
	create: async () => {
		return put('invoice/create');
	},
	stopRenewing: async () => {
		return put('invoice/renew/stop');
	},
	startRenewing: async () => {
		return put('invoice/renew/start');
	},
};

export default invoice;
