import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Results from '../results';
import Notifier from '../notifier';
import { MainButton } from '../buttons';
import { TopMessageContainer, SuccessMessage, ErrorMessage } from '../messages';
import { Meklet } from '../icons';

import { requestData, requestSuggestions, clearSuggestionData, clearRequestData, dataSelectors } from '../../features/data/slice';
import { resetRegisterStatus, registerSelectors } from '../../features/register/slice';
import { createInvoiceSelectors } from '../../features/createInvoice/slice';
import { getStatisticsSelectors } from '../../features/statistics/slice';
import { startRenewingInvoice } from '../../features/renewInvoice/slice';
import { appSelectors } from '../../features/app/slice';

import { makeStyles } from '@mui/styles';
import { Paper, InputBase, IconButton, MenuItem, FormControl, Select, Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	searchContainer: {
		display: 'flex',
		justifyContent: 'space-between',

		'@media (max-width: 850px)': {
			alignItems: 'flex-end',
			display: 'flex',
			flexDirection: 'column-reverse',
			gap: '15px',
		},
	},
	searchBox: {
		backgroundColor: 'unset',
		border: '1px solid #000000',
		borderRadius: '2px',
		display: 'flex',
		alignItems: 'center',
		height: '32px',
		width: '500px',

		'@media (max-width: 850px)': {
			width: '100%',
		},
	},
	searchInput: {
		color: '#000000',
		flex: '1',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '500',
		margin: '8px 27px',
	},
	searchIcon: {
		padding: '8px',

		'& .MuiSvgIcon-root': {
			fill: 'none',
		},
	},
	languageSelectContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	languageSelectLabel: {
		color: '#847D6F',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '600',
		marginRight: '13px',
		textAlign: 'right',
		userSelect: 'none',
	},
	languageSelectOuter: {
		'& .MuiInputBase-root': {
			color: '#000000',
			fontFamily: 'Raleway, Arial, sans-serif',
			fontSize: '14px',
			fontWeight: '500',
			height: '32px',
			padding: '0',
			width: '155px',

			'& .MuiSelect-root': {
				padding: '8px 13px',
			},

			'& .MuiOutlinedInput-notchedOutline': {
				border: '1px solid #000000',
				borderRadius: '2px',
			},
		},

		'& .MuiSelect-icon': {
			color: '#333333',
		},
	},
	languageSelectInput: {
		'& .MuiMenu-list': {
			padding: '0',
		},
	},
	languageSelectOption: {
		backgroundColor: '#000000',
		borderBottom: '1px solid #E0DFDC',
		boxSizing: 'border-box',
		color: '#FFFFFF',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '500',
		padding: '13px',

		'&.MuiPaper-elevation': {
			borderRadius: '2px',
			backgroundColor: '#000000',
		},

		'&.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-root': {
			backgroundColor: '#000000',

			'&:hover': {
				backgroundColor: '#FFFFFF',
				color: '#000000',
			},
		},

		'&:last-of-type': {
			borderBottom: 'unset',
		}
	},
	suggestions: {
		borderRadius: '2px',
		listStyle: 'none',
		margin: '0',
		maxHeight: '150px',
		overflowY: 'auto',
		position: 'absolute',
		top: '152px',
		width: '300px',
		zIndex: '2',

		'@media (max-width: 850px)': {
			left: '20px',
			top: '262px',
		},

		'@media (max-width: 600px)': {
			left: '20px',
			top: '417px',
			width: 'calc(100% - 40px)',
		},
	},
	suggestion: {
		backgroundColor: '#000000',
		borderBottom: '1px solid #E0DFDC',
		boxSizing: 'border-box',
		color: '#FFFFFF',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '500',
		padding: '4px 27px',

		'&:hover': {
			backgroundColor: '#FFFFFF',
			color: '#000000',
			cursor: 'pointer',
		},
	},
	noResults: {
		borderRadius: '2px',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '600',
		marginTop: '34px',
		padding: '4px 16px',
		width: 'fit-content',

		'& .MuiAlert-message': {
			padding: '0',
		}
	},
	backgroundOverlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
		height: '100%',
		left: '0',
		position: 'absolute',
		top: '0',
		width: '100%',
		zIndex: '2',
	},
	overlayContainer: {
		backgroundColor: 'white',
		boxSizing: 'border-box',
		boxShadow: '0 0 5px 1px rgb(0 0 0 / 50%)',
		display: 'flex',
		flexDirection: 'column',
		left: '50%',
		padding: '30px',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		width: '620px',
		zIndex: '1',

		'@media (max-width: 850px)': {
			width: 'calc(100% - 40px)',
		},
	},
	buttonContainer: {
		display: 'flex',
		gap: '30px',
		justifyContent: 'center',
		marginTop: '24px',
	},
}));

const Search = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const [lang, setLang] = useState('lv');
	const [query, setQuery] = useState('');
	const [selectedLanguage, setSelectedLanguage] = useState('lv');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [isTyping, setIsTyping] = useState(false);
	const [showOverlay, setShowOverlay] = useState(false);

	const requestSuccessful = useSelector(dataSelectors.requestSuccessful);
	const requestActive = useSelector(dataSelectors.requestActive);
	const request = useSelector(dataSelectors.request);
	const suggestionRequestActive = useSelector(dataSelectors.suggestionRequestActive);
	const suggestions = useSelector(dataSelectors.suggestions);

	const registerStatus = useSelector(registerSelectors.registerStatus);

	const recentStatistics = useSelector(getStatisticsSelectors.recentStatistics);
	const invoiceEnds = useSelector(createInvoiceSelectors.invoiceEnds);
	const trialPeriod = useSelector(appSelectors.trialPeriod);
	const metadata = useSelector(appSelectors.metadata);

	useEffect(() => {
		setIsTyping(true);
		dispatch(clearSuggestionData());

		if (!requestActive && trimText(query).length >= 3 && canPerformSearch()) {
			getSuggestions(query);
		}
	}, [query]);

	const debounce = (func, wait, immediate) => {
		let timeout;

		return (...args) => {
			const context = this;

			const later = () => {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};

			const callNow = immediate && !timeout;

			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	const getSuggestions = useCallback(
		debounce(query => {
			dispatch(requestSuggestions({ query, lang }));
			setIsTyping(false);
		}, 500),
		[lang]
	);

	const trimText = (text) => {
		return text.replace(/ /g, '');
	}

	const canPerformSearch = (agreeToPay) => {
		if (!invoiceEnds && (recentStatistics >= 20 || !trialPeriod)) {
			if (!metadata.registration_number || !metadata.billing_address || !metadata.billing_email) {
				setErrorMessage('Ir sasniegts bezmaksas limits. Lai turpinātu lietot risinājumu par maksu, aizpildiet visu informāciju sadaļā "Mans konts"!');
				return false;
			}

			setErrorMessage('');

			if (!agreeToPay) {
				setShowOverlay(true);
				return false;

			} else {
				setShowOverlay(false);
				setSuccessMessage('Paldies! Tuvāko dienu laikā e-pastā saņemsiet rēķinu par Burtnieka abonēšanu. Variet turpināt izmantot pakalpojumu!');
			}

			if (!trialPeriod) {
				dispatch(startRenewingInvoice());
			}
		}

		return true;
	}

	const handleSearch = (e, agreeToPay = false) => {
		e.preventDefault();
		if (showOverlay && !agreeToPay) return null;
		dispatch(clearRequestData());

		if ((!e.target.innerText || agreeToPay) && trimText(query).length >= 3) {
			if (!canPerformSearch(agreeToPay)) {
				return null
			}

			dispatch(clearSuggestionData());
			setSelectedLanguage(lang);
			dispatch(requestData({ lang, query }));

		} else if (e.target.innerText && trimText(e.target.innerText).length >= 3) {
			if (!canPerformSearch(agreeToPay)) {
				return null
			}

			dispatch(clearSuggestionData());
			setSelectedLanguage(lang);
			dispatch(requestData({ lang: lang, query: e.target.innerText }));
		}
	}

	const showResults = () => {
		if (!isTyping &&
				trimText(query).length >= 3 &&
				!requestActive &&
				requestSuccessful &&
				(request === undefined || (request !== undefined && request.length === 0)) &&
				!suggestionRequestActive &&
				(suggestions === undefined || (suggestions !== undefined && suggestions.length === 0))) {

			return <Alert className={classes.noResults} icon={false} severity="error">Nav rezultātu!</Alert>;
		}

		if (request !== undefined && request.length !== 0) {
			return (
				<Results
					language={selectedLanguage}
					request={request}
				></Results>
			);
		}
	}

	const handleKeyPress = (e) => {
		if (showOverlay) {
			e.preventDefault();
			return null;
		}
	}

	const handleClick = (e) => {
		dispatch(clearSuggestionData());
		setQuery(e.target.innerText);
		handleSearch(e);
	};

	let suggestionsList = '';
	const filteredSuggestions = [...new Set(suggestions)];

	if (filteredSuggestions.length > 0 && query) {
		suggestionsList =
		<ul className={classes.suggestions}>
			{filteredSuggestions.map((suggestion) => {
				return (
					<li className={classes.suggestion} key={suggestion} onClick={handleClick}>
						<span>{suggestion}</span>
					</li>
				);
			})}
		</ul>;
	}

	if (registerStatus === 'success') {
		dispatch(resetRegisterStatus());
		setSuccessMessage('Lietotāja konts tika veiksmīgi izveidots!');

	} else if (searchParams.get('token')) {
		setSuccessMessage('Parole tika veiksmīgi atjaunota!');
		searchParams.delete('token');
		setSearchParams(searchParams);
		window.history.replaceState('', '', '/');
	}

	const overlay = !showOverlay ? ''
		: (
			<div className={classes.backgroundOverlay}>
				<div className={classes.overlayContainer}>
					<span>Ir sasniegts bezmaksas limits. Lai turpinātu izmantot pakalpojumu, nospiediet uz pogas "Piekrītu pakalpojuma abonēšanai"</span>

					<div className={classes.buttonContainer}>
						<MainButton onClick={() => setShowOverlay(false)}>Aizvērt</MainButton>
						<MainButton onClick={(e) => handleSearch(e, true)}>Piekrītu pakalpojuma abonēšanai</MainButton>
					</div>
				</div>
			</div>
		);

	return (
		<div>
			<TopMessageContainer>
				{successMessage !== '' && <SuccessMessage message={successMessage} onClose={() => { setSuccessMessage('') }} />}
				{errorMessage !== '' && <ErrorMessage message={errorMessage} onClose={() => { setErrorMessage('') }} />}
			</TopMessageContainer>

			<div>
				<div className={classes.searchContainer}>
					<Paper component="form" className={classes.searchBox} variant="outlined" square onSubmit={handleSearch}>
						<InputBase
							className={classes.searchInput}
							placeholder="Meklēt"
							value={query}
							type="text"
							onKeyDown={handleKeyPress}
							onChange={(event) => setQuery(event.target.value)}
						/>
						<IconButton onClick={handleSearch} className={classes.searchIcon} size="medium">
							<Meklet viewBox="-2 -2 20 20" />
						</IconButton>
					</Paper>

					{suggestionsList}

					<div className={classes.languageSelectContainer}>
						<span className={classes.languageSelectLabel}>Meklēšanas valoda</span>
						<FormControl variant="outlined" className={classes.languageSelectOuter}>
							<Select
								value={lang}
								onChange={(event) => setLang(event.target.value)}
								className={classes.languageSelectInput}
							>
								<MenuItem value={'lv'} className={classes.languageSelectOption}>Latviski</MenuItem>
								<MenuItem value={'et'} className={classes.languageSelectOption}>Igauniski</MenuItem>
								{/* <MenuItem value={'lt'} className={classes.languageSelectOption}>Lietuviski</MenuItem> */}
								<MenuItem value={'orig'} className={classes.languageSelectOption}>Oriģinālvalodā</MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>

				{overlay}
				{showResults()}
			</div>

			<Notifier />
		</div>
	);
}

export default Search;
