import React, { useState, useEffect } from 'react';

import MenuList from '../menuList';

import { countryIsoCodes, languageIsoCodes } from '../../helpers/isoCodes';

import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	menuContainer: {
		alignItems: 'flex-start',
		display: 'flex',
		gap: '40px',
		justifyContent: 'space-between',
		marginTop: '34px',

		'@media (max-width: 850px)': {
			flexDirection: 'column',
		},
	},
	resultsContentContainer: {
		backgroundColor: '#000000',
		borderRadius: '2px',
		boxSizing: 'border-box',
		padding: '24px 32px',
		width: '500px',

		'@media (max-width: 850px)': {
			width: '100%',
		},
	},
	contentBoxContainer: {
		alignItems: 'stretch',
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
		marginTop: '22px',
	},
	contentBox: {
		backgroundColor: '#FFFFFF',
		borderRadius: '2px',
		boxSizing: 'border-box',
		padding: '14px 21px',
		width: '100%',
	},
	languageTitle: {
		color: '#847D6F',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '600',
	},
	mainName: {
		color: '#000000',
		padding: '3px 0 1px 0',
	},
	extraName: {
		color: '#000000',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '500',
	},
	placeHeading: {
		color: '#E0DFDC',
	},
	languageHeading: {
		color: '#E0DFDC',
		fontFamily: 'Raleway, Arial, sans-serif',
		fontSize: '14px',
		fontWeight: '500',
		marginTop: '9px',
	},
}));

const Results = ({ request, language }) => {
	const classes = useStyles();

	const [selectedIndex, setSelectedIndex] = useState(0);
	const [selectedIndexData, setSelectedIndexData] = useState([]);

	const setCurrentRequestData = (id) => {
		setSelectedIndex(id);

		if (request !== []) {
			request.every(item => {
				if (item.properties.id === id) {
					setSelectedIndexData(item);
					return false;
				}

				return true;
			});
		}
	}

	const getQueryItemNames = (item) => {
		const category = item.properties.object.category;

		if (language === 'lv') {
			return item.properties.latvian.original + ' (' + category + ')';

		} else if (language === 'et') {
			return item.properties.estonian[0] + ' (' + category + ')';

		} else if (language === 'lt') {
			return item.properties.lithuanian[0] + ' (' + category + ')';

		} else if (language === 'orig') {
			return item.properties.original.original + ' (' + category + ')';
		}
	}

	const getSortedResultList = (list) => {
		list.sort((a, b) => {
			const nameA = getQueryItemNames(a).toUpperCase();
			const nameB = getQueryItemNames(b).toUpperCase();

			if (nameA < nameB) return -1;
			if (nameA > nameB) return 1;
			return 0;
		});

		return list;
	}

	const getDescription = () => {
		if (selectedIndexData.length === 0) {
			return;
		}

		const latvian = {};
		const estonian = {};
		const lithuanian = {};

		latvian.data = selectedIndexData.properties.latvian ? selectedIndexData.properties.latvian : '';
		estonian.data = selectedIndexData.properties.estonian ? selectedIndexData.properties.estonian : '';
		lithuanian.data = selectedIndexData.properties.lithuanian ? selectedIndexData.properties.lithuanian : '';
		let original = '';

		if (selectedIndexData.properties.original.original && selectedIndexData.properties.original.original_transcription) {
			original = selectedIndexData.properties.original.original + ' (' + selectedIndexData.properties.original.original_transcription + ')';

		} else if (selectedIndexData.properties.original.original) {
			original = selectedIndexData.properties.original.original;
		}

		const entryCountry = countryIsoCodes[selectedIndexData.properties.object.iso[0]];
		let entryLanguage = languageIsoCodes[selectedIndexData.properties.original.original_iso.toLowerCase()];

		if (!entryLanguage && entryCountry === 'Latvija') {
			entryLanguage = 'Latviešu valoda';
		}

		latvian.alternativeNames = [];
		latvian.data.alternative ? latvian.alternativeNames.push(latvian.data.alternative) : '';
		latvian.data.local ? latvian.alternativeNames.push(latvian.data.local) : '';
		latvian.data.traditional ? latvian.alternativeNames.push(latvian.data.traditional) : '';

		estonian.alternativeNames = [];
		if (estonian.data.length > 1) {
			for (let i = 1; i < estonian.data.length; i++) {
				estonian.alternativeNames.push(estonian.data[i]);
			}
		}

		lithuanian.alternativeNames = [];
		if (lithuanian.data.length > 1) {
			for (let i = 1; i < lithuanian.data.length; i++) {
				lithuanian.alternativeNames.push(lithuanian.data[i]);
			}
		}

		return (
			<div className={classes.resultsContentContainer}>
				<h4 className={classes.placeHeading}>{original} ({selectedIndexData.properties.object.category})</h4>
				<span className={classes.languageHeading}>{entryCountry} | {entryLanguage}</span>

				<div className={classes.contentBoxContainer}>
					{latvian.data.original &&
						<Paper elevation={0} className={classes.contentBox} square>
							<span className={classes.languageTitle}>Latviski:</span>
							<h4 className={classes.mainName}>{latvian.data.original}</h4>

							{latvian.alternativeNames.length > 0 &&
								<span className={classes.extraName}>Varianti: {latvian.alternativeNames.toString().split(',').join(', ')}</span>
							}
						</Paper>
					}
					{estonian.data[0] &&
						<Paper elevation={0} className={classes.contentBox} square>
							<span className={classes.languageTitle}>Igauniski:</span>
							<h4 className={classes.mainName}>{estonian.data[0]}</h4>

							{estonian.alternativeNames.length > 0 &&
								<span className={classes.extraName}>Varianti: {estonian.alternativeNames.toString().split(',').join(', ')}</span>
							}
						</Paper>
					}
					{lithuanian.data[0] &&
						<Paper elevation={0} className={classes.contentBox} square>
							<span className={classes.languageTitle}>Lietuviski:</span>
							<h4 className={classes.mainName}>{lithuanian.data[0]}</h4>

							{lithuanian.alternativeNames.length > 0 &&
								<span className={classes.extraName}>Varianti: {lithuanian.alternativeNames.toString().split(',').join(', ')}</span>
							}
						</Paper>
					}
				</div>
			</div>
		);
	}

	const sorted = getSortedResultList([...request]);

	useEffect(() => {
		if (sorted.length === 1) {
			setCurrentRequestData(sorted[0].properties.id);
		}
	}, [sorted]);

	return (
		<div className={classes.menuContainer}>
			<MenuList listContents={sorted} onClick={setCurrentRequestData} selectedIndex={selectedIndex} itemNameModifier={getQueryItemNames} />

			{getDescription()}
		</div>
	);
}

export default Results;
