import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { setUser, setTrialPeriod } from '../app/slice';
import { setInvoice } from '../createInvoice/slice';
import { getStatistics, resetStatistics } from '../statistics/slice';
import { changeMetadata } from '../metadata/slice';

const defaultState = {
	isAuthenticated: false,
	loggingIn: true,
	authError: '',
	authRequest: null,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState: defaultState,
	reducers: {
		authSuccess: (state, action) => {
			state.isAuthenticated = true;
			state.authRequest = 'idle';
			state.loggingIn = false;
		},
		authFailure: (state, action) => {
			state.authError = action.payload;
			state.isAuthenticated = false;
			state.authRequest = 'idle';
			state.loggingIn = false;
		},
		authAttemptStarted: (state, action) => {
			state.authError = '';
			state.authRequest = 'started';
		},
		authAttemptFinished: (state, action) => {
			state.authRequest = 'idle';
			state.loggingIn = false;
		},
		logout: (state, action) => {
			return { ...defaultState, loggingIn: false };
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
		resetStatus: (state, action) => {
			state.authError = '';
		},
	},
});

const { actions } = authSlice;

export const checkAuth = () => (dispatch, getState) => {
	dispatch(actions.authAttemptStarted());

	api.user.status()
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, user, invoice, trialPeriod } = resp;

			if (status === 'blocked') {
				dispatch(logout());
				dispatch(actions.authFailure('Lietotāja konts ir bloķēts! Lai atbloķētu kontu, rakstiet uz support@kartes.lv!'));

			} else if (status === 'success') {
				dispatch(setUser({
					userId: user.id,
					admin: user.is_admin,
					username: user.name,
					email: user.email,
					email_verified_at: user.email_verified_at,
					metadata: user.metadata,
					tos_accepted: user.tos_accepted,
					created_at: user.created_at
				}));

				if (invoice) {
					dispatch(setInvoice({ invoiceStarts: invoice.created_at, invoiceEnds: invoice.ends_at, invoiceRenewed: invoice.is_renewed, invoicePaid: invoice.is_paid }));

				} else {
					dispatch(setInvoice({ invoiceStarts: null, invoiceEnds: null, invoiceRenewed: null, invoicePaid: null }));
				}

				dispatch(setTrialPeriod(trialPeriod));
				dispatch(getStatistics());
				dispatch(actions.authSuccess());

			} else {
				dispatch(actions.authAttemptFinished());
			}
		})
		.catch(e => {
			dispatch(actions.authAttemptFinished());
		})
};

export const login = ({ email, password }) => (dispatch, getState) => {
	dispatch(actions.authAttemptStarted());

	api.auth.login({ email, password })
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, user, invoice, trialPeriod } = resp;

			if (status === 'error') {
				// dispatch(enqueueToSnack({ message: 'Jūsu piekļuves sesija nav derīga', variant: 'error' }));
				dispatch(actions.authFailure('E-pasts un/vai parole nav pareiza!'));
				return;

			} else if (status === 'blocked') {
				dispatch(actions.authFailure('Lietotāja konts ir bloķēts! Lai atbloķētu kontu, rakstiet uz support@kartes.lv!'));
				return;
			}

			if (status === 'success') {
				dispatch(actions.authSuccess());
				dispatch(setUser({
					userId: user.id,
					admin: user.is_admin,
					username: user.name,
					email: user.email,
					email_verified_at: user.email_verified_at,
					metadata: user.metadata,
					tos_accepted: user.tos_accepted,
					created_at: user.created_at
				}));

				dispatch(resetStatistics());

				if (invoice) {
					dispatch(setInvoice({ invoiceStarts: invoice.created_at, invoiceEnds: invoice.ends_at, invoiceRenewed: invoice.is_renewed, invoicePaid: invoice.is_paid }));

				} else {
					dispatch(setInvoice({ invoiceStarts: null, invoiceEnds: null, invoiceRenewed: null, invoicePaid: null }));
				}

				dispatch(setTrialPeriod(trialPeriod));
				dispatch(getStatistics());

				if (user.metadata === null) {
					const metadata = {
						company: null,
						billing_address: null,
						billing_email: null,
						registration_number: null,
						vat_number: null
					};
					dispatch(changeMetadata({ metadata: metadata, tos_accepted: true }));
				}
			}
		})
		.catch(() => {
			dispatch(actions.authFailure('Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju'));
			// dispatch(enqueueToSnack({ message: 'Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju', variant: 'error' }));
		});
};

export const logout = () => (dispatch, getState) => {
	api.auth.logout();
	dispatch(actions.logout());
};

export const resetAuthStatus = () => (dispatch, getState) => {
	dispatch(actions.resetStatus());
};

export const selectors = {
	isAppReady: state => !state.auth.loggingIn,
	error: state => state.auth.authError,
	authSuccess: state => state.auth.isAuthenticated,
	isAuth: state => state.auth.isAuthenticated,
	authStatus: state => state.auth.authRequest,
};

export default authSlice.reducer;
