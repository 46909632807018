import { get, post, put } from '../api';

const auth = {
	login: async ({ email, password }) => {
		return post('login', { email, password });
	},
	logout: async () => {
		return post('logout');
	},
};

export default auth;
