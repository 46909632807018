import { createSlice } from '@reduxjs/toolkit';

const defaultState = {
	user_id: null,
	admin: false,
	username: null,
	email: null,
	email_verified_at: null,
	metadata: {},
	tos_accepted: false,
	created_at: null,
	trialPeriod: null,
};

export const appSlice = createSlice({
	name: 'app',
	initialState: defaultState,
	reducers: {
		setUser: (state, action) => {
			const { userId, admin, username, email, email_verified_at, metadata, tos_accepted, created_at } = action.payload;
			state.user_id = userId;
			state.admin = !!admin;
			state.username = username;
			state.email = email;
			state.email_verified_at = email_verified_at;

			if (metadata === null) {
				state.metadata = {
					company: null,
					billing_address: null,
					billing_email: null,
					registration_number: null,
					vat_number: null
				};

			} else {
				state.metadata = metadata;
			}

			state.tos_accepted = !!tos_accepted;
			state.created_at = created_at;
		},
		setTrialPeriod: (state, action) => {
			state.trialPeriod = action.payload;
		},
	},
});

const { actions } = appSlice;

export const setUser = ({ userId, admin, username, email, email_verified_at, metadata, tos_accepted, created_at }) => (dispatch, getState) => {
	dispatch(actions.setUser({ userId, admin, username, email, email_verified_at, metadata, tos_accepted, created_at }));
};

export const setTrialPeriod = (trialPeriod) => (dispatch, getState) => {
	dispatch(actions.setTrialPeriod(trialPeriod));
};

export const appSelectors = {
	username: state => state.app.username,
	isAdmin: state => state.app.admin,
	email: state => state.app.email,
	email_verified_at: state => state.app.email_verified_at,
	metadata: state => state.app.metadata,
	tos_accepted: state => state.app.tos_accepted,
	created_at: state => state.app.created_at,
	trialPeriod: state => state.app.trialPeriod,
};

export default appSlice.reducer;
