export const validateEmail = (email, parameterName) => {
	let error = '';

	if (email.length === 0) {
		error = 'Ievadiet ' + parameterName + '!';

	} else if (email.length > 0 && !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
		error = 'Ievadiet korektu ' + parameterName + '!';
	}

	return error;
}

export const validateStringRequired = (string, parameterName) => {
	let error = '';

	if (string.length === 0) {
		error = 'Ievadiet ' + parameterName + '!';
	}

	return error;
}

export const validatePassword = (password, parameterName) => {
	let error = '';

	if (password.length === 0) {
		error = 'Ievadiet ' + parameterName + '!';
	}

	return error;
}

export const validatePasswordMatch = (password, passwordRepeat) => {
	let error = '';

	if (password.length > 0 && password !== passwordRepeat) {
		error = 'Ievadītajām parolēm ir jāsakrīt!';
	}

	return error;
}

export const validateRegistrationNumber = (string, parameterName) => {
	let error = '';

	if (string.length === 0) {
		error = 'Ievadiet ' + parameterName + '!';

	} else if (string.length > 0 && !(string.match(/^[0-9]{11}$/g) || string.match(/^[0-9]{6}-[0-9]{5}$/g))) {
		error = 'Ievadiet korektu ' + parameterName + '!';
	}

	return error;
}

export const validateVatNumber = (string, parameterName) => {
	let error = '';

	if (string.length > 0 && !string.match(/^LV[0-9]{11}$/i)) {
		error = 'Ievadiet korektu ' + parameterName + '!';
	}

	return error;
}
