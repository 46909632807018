import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';

const defaultState = {
	sending: false,
	sendError: '',
	sendStatus: null,
};

export const forgotPasswordSlice = createSlice({
	name: 'forgotPassword',
	initialState: defaultState,
	reducers: {
		forgotPasswordSuccess: (state, action) => {
			state.sendStatus = 'success';
			state.sending = false;
		},
		forgotPasswordFailure: (state, action) => {
			state.sendError = action.payload;
			state.sendStatus = 'idle';
			state.sending = false;
		},
		forgotPasswordAttemptStarted: (state, action) => {
			state.sendError = '';
			state.sendStatus = 'started';
			state.sending = true;
		},
		resetStatus: (state, action) => {
			state.sendError = '';
			state.sendStatus = 'idle';
		},
	},
});

const { actions } = forgotPasswordSlice;

export const forgotPassword = ({ email }) => (dispatch, getState) => {
	dispatch(actions.forgotPasswordAttemptStarted());

	api.user.forgotPassword({ email })
		.then((resp) => resp.json())
		.then((resp) => {
			const { status, message, user } = resp;

			if (status === 'error') {
				dispatch(actions.forgotPasswordFailure('Lietotājs ar šādu e-pasta adresi neeksistē. Lūdzu, pārbaudiet ievadīto e-pasta adresi vai reģistrējieties!'));
				return;
			}

			if (status === 'blocked') {
				dispatch(actions.forgotPasswordFailure('Lietotāja konts ir bloķēts! Lai atbloķētu kontu, rakstiet uz support@kartes.lv!'));
				return;
			}

			if (status === 'success') {
				dispatch(actions.forgotPasswordSuccess());
			}
		})
		.catch(() => {
			dispatch(actions.forgotPasswordFailure('Notika neparedzēta kļūda, kontaktējieties ar sistēmas turētāju'));
		});
};

export const resetForgotPasswordStatus = () => (dispatch, getState) => {
	dispatch(actions.resetStatus());
};

export const passwordSelectors = {
	isSending: state => state.forgotPassword.sending,
	error: state => state.forgotPassword.sendError,
	sendStatus: state => state.forgotPassword.sendStatus,
};

export default forgotPasswordSlice.reducer;
