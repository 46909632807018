import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	textBox: {
		width: '500px',

		'& hr': {
			margin: '20px 0',
		},

		'& p': {
			marginBottom: '5px',
		},

		'@media (max-width: 850px)': {
			width: '100%',
		},
	}
}));

const Contacts = () => {
	const classes = useStyles();

	return (
		<div className={classes.textBox}>
			<p>Jautājumu gadījumā aicinām sazināties ar mums e-pastā <a href="mailto:support@kartes.lv">support@kartes.lv</a> vai izmantojot klientu atbalsta tālruni: +371 27742042.</p>

			<hr />

			<p>SIA “Jāņa sēta”</p>
			<p>Krasta iela 105a, Rīga, Latvija, LV-1019</p>
			<p>Vienotais reģistrācijas nr. 40003426448</p>
			<p>PVN reģistrācijas nr. LV40003426448</p>
			<p>Swedbank, HABALV22, konta nr. LV98HABA0551002174933</p>
			<p>SEB banka, UNLALV2X, konta nr. LV59UNLA0034108263100</p>
		</div>
	);
}

export default Contacts;
