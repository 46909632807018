import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export const Burtnieks = props => (
	<SvgIcon viewBox="0 0 57 57" {...props}>
		<g>
			<path d="M53.98 32.46C52.11 30.01 49.56 28.31 46.35 27.36C48.99 26.2 50.99 24.42 52.36 22.02C53.73 19.62 54.42 17.05 54.42 14.31C54.42 12.57 54.12 10.85 53.51 9.12999C52.9 7.41999 52.05 5.88999 50.94 4.53999C49.83 3.18999 48.49 2.09999 46.91 1.25999C45.33 0.41999 43.56 -0.0100098 41.61 -0.0100098H38.52L32.18 17.41L13.74 10.48C13.22 10.29 12.65 10.55 12.45 11.06C12.26 11.58 12.52 12.15 13.03 12.35L31.49 19.29L29.79 23.95L1.34999 13.48C0.82999 13.29 0.25999 13.55 0.0699898 14.07C-0.12001 14.59 0.13999 15.16 0.65999 15.35L15.04 20.65L10.18 34.04C9.97999 34.6 10.27 35.22 10.83 35.42L16.98 37.65L12.19 50.4C12 50.92 12.26 51.49 12.77 51.69C12.89 51.73 13 51.75 13.12 51.75C13.52 51.75 13.91 51.5 14.06 51.1L16.76 43.91L21.86 45.76L18.09 56.13H38.69C41.17 56.13 43.5 55.81 45.69 55.18C47.88 54.55 49.8 53.63 51.46 52.41C53.12 51.2 54.43 49.7 55.37 47.9C56.32 46.11 56.79 44.03 56.79 41.66C56.79 37.97 55.85 34.9 53.98 32.45V32.46ZM29.11 25.85L26.64 32.63L21.76 30.86L24.23 24.06L29.11 25.86V25.85ZM16.99 21.62L22.14 23.49L17.73 35.64L12.58 33.77L16.99 21.62ZM17.6 42.09L19.18 37.75C19.28 37.65 19.35 37.52 19.4 37.39L21.09 32.73L25.97 34.5L22.55 43.88L17.6 42.08V42.09Z" fill="black" />
			<path d="M25 12.9299C25.54 12.9299 26.09 12.8399 26.63 12.6599C29.29 11.7599 30.72 8.85995 29.82 6.19995C29.38 4.90995 28.47 3.86995 27.25 3.26995C26.03 2.66995 24.65 2.57995 23.36 3.00995C22.07 3.44995 21.03 4.35995 20.43 5.57995C19.83 6.79995 19.73 8.17995 20.17 9.46995C20.89 11.5899 22.87 12.9299 25 12.9299ZM22.22 6.46995C22.59 5.72995 23.22 5.17995 24 4.90995C24.33 4.79995 24.66 4.74995 24.99 4.74995C25.46 4.74995 25.93 4.85995 26.36 5.06995C27.1 5.43995 27.65 6.06995 27.92 6.84995C28.47 8.46995 27.6 10.2199 25.98 10.7699C24.36 11.3199 22.61 10.4499 22.06 8.82995C21.8 8.04995 21.85 7.20995 22.22 6.46995Z" fill="black" />
		</g>
	</SvgIcon>
);

export const Meklet = props => (
	<SvgIcon viewBox="0 0 16 16" {...props}>
		<g>
			<circle cx="10.1246" cy="5.64706" r="5.14706" stroke="black" />
			<path d="M0.587609 14.7053C0.392347 14.9005 0.392347 15.2171 0.587609 15.4124C0.782871 15.6077 1.09945 15.6077 1.29472 15.4124L0.587609 14.7053ZM6.94177 9.76533L7.29533 9.41178L6.58822 8.70467L6.23467 9.05823L6.94177 9.76533ZM1.29472 15.4124L6.94177 9.76533L6.23467 9.05823L0.587609 14.7053L1.29472 15.4124Z" fill="black" />
		</g>
	</SvgIcon>
);
